import { useContext, useState} from 'react';
import { Outlet , useLocation,useNavigate} from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Avatar,Spin,theme,Modal, Row, Col } from 'antd';
import { AuthContext } from '../Services/AuthService';
import { GlobalConfig } from '../App';
const { Header, Content, Footer, Sider } = Layout;

function getItem(key,target, label,icon,children) {
  return {
    key,
    target,
    icon,
    children,
    label,
  };
}
const items = {
  'Administrator':[
                    getItem('root', 'home', 'Home', <Icons.HomeOutlined />),
                    getItem('root0', 'accounts', 'Accounts', <Icons.UserOutlined />,[
                          getItem('root0.1','accounts', 'General', <Icons.SettingOutlined  />),
                          getItem('root0.2','customers', 'Customers', <Icons.TagOutlined  />),
                          getItem('root0.3','sessions', 'Sessions', <Icons.UserOutlined />),
                    ]),
                    //getItem('root1','flows', 'Flujos', <PullRequestOutlined  />, [
                    //  getItem('3','flows', 'Manage', <SettingOutlined  />),
                    //  getItem('4','builder', 'Builder',<ApartmentOutlined />)
                    //]),
                  //  getItem('root1','flows', 'Flows', <Icons.PullRequestOutlined  />),
                    getItem('root3','chats', 'Chats', <Icons.CommentOutlined />),
                    getItem('root5','users', 'Users', <Icons.TeamOutlined  />)
                  ],
   'Manager':[
                    getItem('root', 'home', 'Home', <Icons.HomeOutlined />),
                    getItem('root0', 'accounts', 'Accounts', <Icons.UserOutlined />,[
                          getItem('root0.1','accounts', 'General', <Icons.SettingOutlined  />),
                          getItem('root0.2','customers', 'Customers', <Icons.TagOutlined  />),
                          getItem('root0.3','sessions', 'Sessions', <Icons.UserOutlined />),
                    ]),
                    getItem('root3','chats', 'Chats', <Icons.CommentOutlined />),
                  ],                        
   'Supervisor':[
                    getItem('root', 'home', 'Home', <Icons.HomeOutlined />),
                    getItem('roo0.2','customers', 'Customers', <Icons.TagOutlined  />),
                    getItem('root3','chats', 'Chats', <Icons.CommentOutlined />),

                  ],      
    'Agent':[
                    getItem('root', 'home', 'Home', <Icons.HomeOutlined />),
                    getItem('roo0.2','customers', 'Customers', <Icons.TagOutlined  />),
                    getItem('root3','chats', 'Chats', <Icons.CommentOutlined />),
                  ],                

};


const RenderLayout = ({ onLogout }) => {
  const {    token: { colorBgContainer }  } = theme.useToken();
  const [LogoutModalVisible, setLogoutModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(JSON.parse(localStorage.getItem("CollapseAppPanel") )|| false);
  const {loading, userData,isLogged,_logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const page = location.pathname.substring(1); // Remover la barra inicial

      const capitalizeWords = (str) => {
        return str
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };


      const handleLogout= () => {
        setLogoutModalVisible(true);
      };
          
      const handleLogoutConfirm = async () => {

              if (isLogged) {
                window.localStorage.removeItem("token")
                await _logout()
                setTimeout(() => {
                  navigate("/login")
                }, 1500);

              }

              // Aquí puedes realizar la lógica de borrado
              setLogoutModalVisible(false);
      };

      const handleLogoutCancel = () => {
        setLogoutModalVisible(false);
      };

      const handleMenuClick = (e) => {
        const targetKey = e.key;
        const findSelectedItem = (items, key) => {
          for (let item of items) {
            if (item.key === key) {
              return item;
            }
            if (item.children) {
              const selectedItem = findSelectedItem(item.children, key);
              if (selectedItem) {
                return selectedItem;
              }
            }
          }
        };

        const selectedItem = findSelectedItem(items[userData.role], targetKey);
        const target = selectedItem ? selectedItem.target : '';
        navigate("/" + target);

      };

      const handleMenu_ProfileClick = (e) => {
        if (e.key === 'Setting:Profile') {
          navigate('/profile');
        } else if (e.key === 'Setting:Logout') {
          handleLogout()
        }
      };
      const items_profile = [
        {
          key: '0',
          icon : <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }} align={'bottom'} src ={userData.avatar} size={35} justify={'middle'} >{userData.avatar||userData.username.slice(0,3)}</Avatar>,
          children: [
            {
              label: 'Profile',
              icon : <Icons.UserOutlined />,
              key: 'Setting:Profile',
            },
            {
              label: 'Logout',
              icon : <Icons.LogoutOutlined />,
              key: 'Setting:Logout',
            },
          ],
        },
      ];

      const handleCollapseAppPanel= (value) => {
        localStorage.setItem("CollapseAppPanel",value)
        setCollapsed(value)
      }

 return (
   <Layout
     style={{
       minHeight: '100vh',
     }}
   >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => handleCollapseAppPanel(value)}>
        <Menu  onClick={handleMenuClick} theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items[userData.role]}></Menu>
      </Sider>
      <Layout>
            <Header style={{minHeight:40, padding: 8,paddingLeft:15, background: colorBgContainer }}>
                <Row justify={'start'} align={'middle'}>
                        <Col span={22}>
                            <Breadcrumb
                              separator=">"
                              items={[
                                {
                                  title: <Icons.HomeOutlined />,
                                  href: '/home'
                                },
                                {
                                  title: (page==='home')?'': capitalizeWords(page.substring(0, page.indexOf("/"))),
                                  href: (page==='home')?'': '/'+ capitalizeWords(page.substring(0, page.indexOf("/")))
                                },
                              ]}
                            />
                        </Col>
                        <Col span={2} flex={1} align={'middle'} justify={'end'} >
                          <Menu align={'middle'} justify={'end'}  onClick={handleMenu_ProfileClick} mode={'horizontal'} triggerSubMenuAction= {'click'} items={items_profile} style={{maxHeight:'45px',borderBottom:'none',color:'transparent'}}/>
                        </Col>
                </Row>
            </Header>
            <Content
              style={{
                margin: '0 16px',
              }}
            >
              <Outlet></Outlet>
            </Content>
            <Footer
              style={{
                textAlign: 'center',
              }}
            >
              Flow Interactions WebApp  v.<strong>{GlobalConfig.VERSION}</strong>
              <Spin spinning={loading} style={{ position: 'fixed', right: 16, bottom: 16 }} />
            </Footer>
            <Modal
                                        title="Log out"
                                        open={LogoutModalVisible}
                                        onOk={handleLogoutConfirm}
                                        onCancel={handleLogoutCancel}
                              >
                                        <p>¿ Are you sure :( ?</p>
                              </Modal>
      </Layout>
   </Layout>
 );
};

export default RenderLayout;