import React, { useState, useEffect, useRef } from 'react';
import { Layout, Tooltip, Row, Col } from 'antd';
import * as Icons from '@ant-design/icons';
import './ChatWindow.css'
import { Timeline } from 'antd';
import {formatSpanishDate} from '../Services/APIService'

const TimelineWindow = ({ session,  onReceived}) => {
  const [nodes, setnodes] = useState([]);
  const chatContainerRef = useRef(null);

//  useEffect(() => {
//  
//    if (session.history) {
//      setnodes(session.history.map((node, index) => ({
//        id: node.id,
//        type:node.type,
//        payload: node.payload,
//        author: node.author,
//        timestamp:node.updatedAt 
//      })));
//    }
//  }, [session.history]);


  useEffect(() => {
    if (onReceived) {
          setnodes(onReceived.nodes);
          // Scroll automático al recibir un nuevo mensaje
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          }
    }
  }, [onReceived]);

  useEffect(() => {
    // Scroll automático al recibir un nuevo mensaje
    if (chatContainerRef.current) {
              chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
}, [nodes]);


  const getIconForMessageType = (nodeType) => {
    switch (nodeType) {
      
      case 'start':
        return <Icons.PlayCircleOutlined style={{ fontSize: '25px', color: '#00aa00', backgroundColor: 'transparent' }} />;
      case 'end':
        return <Icons.CheckCircleOutlined style={{ fontSize: '25px', color: '#cc0000', backgroundColor: 'transparent' }} />;
      case 'HttpRequest':
          return <Icons.GlobalOutlined style={{ fontSize: '25px', color: '#08c', backgroundColor: 'transparent' }} />;
      case 'message':
        return <Icons.MessageOutlined style={{ fontSize: '25px', color: '#08c', backgroundColor: 'transparent' }} />;
      case 'log':
        return <Icons.CodeOutlined style={{ fontSize: '25px', color: '#08c', backgroundColor: 'transparent' }} />;
      default:
        return null;
    }
  };
  return (
    <Row>
    <Col span={24}>
      <Row>
        <Col span={12} style={{fontSize:'22px',lineHeight:'22px'}}>
            <Icons.LogoutOutlined style={{fontSize:'22px'}} /> {session?.uid.split("_")[1]}
        </Col>
        <Col span={12} style={{textAlign:'end',lineHeight:'22px'}}>
             {session?.uid.split("_")[0]} <Icons.LoginOutlined style={{fontSize:'22px'}}  />
        </Col>
      </Row>
    </Col>
    <Col span={12} style={{textAlign:'start',lineHeight:'22px'}}>
        <Icons.NotificationOutlined ></Icons.NotificationOutlined> {formatSpanishDate(nodes[nodes.length - 1]?.updatedAt)||'Loading'}
    </Col>
    <Col span={12} style={{textAlign:'end',lineHeight:'22px'}}>
     {nodes.length || 0} messages <Icons.HistoryOutlined ></Icons.HistoryOutlined>
    </Col>   
    <Layout>
      <Layout className="chat-window">
          <Layout className="chat-messages WebHook-messages" ref={chatContainerRef}>
            <Timeline  pending="Waiting..." items={nodes.map((node, index) => ({
              dot:   
                <Tooltip title={`Tipo: ${node.type} ${formatSpanishDate(node.timestamp)}`}>
                  {getIconForMessageType(node.type)}
                </Tooltip>,
              children:(<>{node.payload?.msg|| node.type}<p style={{'marginTop':'0px','marginBottom':'0px','display':'flex','justifyContent': 'flex-start'}}>{ formatSpanishDate( node.updatedAt || node.timestamp)}</p></>),
              key: index,
            }))} />
          </Layout>
        </Layout>
    </Layout>
    </Row>
  );
};

export default TimelineWindow;