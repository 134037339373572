import React, { useEffect, useState,useCallback ,useRef  } from 'react';
 import ReactFlow, {
   MiniMap,
   Controls,
   Background,
   useNodesState,
   useEdgesState,
   addEdge,
   ReactFlowProvider,
   MarkerType ,
   getIncomers,
   getOutgoers,
   getConnectedEdges
 }  from 'reactflow';

import {SaveOutlined} from '@ant-design/icons';
 
import 'reactflow/dist/style.css';
import {
  ConnectionLine,
  BuilderLibrary,
  BlockConditional,
  BlockStart,
  BlockGeneric,
  BlockEnd,
  BlockHttpRequest,
  BlockImage,
  BlockOption,
  BlockinputText,
  BlockMessage} from './BuilderLibrary';

import NodeEdge from './NodeEdge';
import EdgeEditor from './EdgeEditor';

import { Layout, Button,Spin } from 'antd';

import Sider from 'antd/es/layout/Sider';

import './BuilderLibrary.css'
import { useParams } from 'react-router-dom';
import { showSuccessNotification } from '../Services/notifications';
import { API_Request } from './../Services/APIService';

const nodeTypes = {
          start: BlockStart,
          log: BlockGeneric,
          message: BlockMessage,
          conditional: BlockConditional,
          switch: BlockOption,
          inputText: BlockinputText,
          inputDate: BlockGeneric,
          inputNumber: BlockGeneric,
          setVariable: BlockGeneric,
          setAtribute: BlockGeneric,
          optionButton: BlockOption,
          optionList: BlockOption,
          image: BlockImage,
          file: BlockGeneric,
          audio: BlockGeneric,
          video: BlockGeneric,
          sticker: BlockImage,
          location: BlockGeneric,
          document: BlockGeneric,
          HttpRequest: BlockHttpRequest,
          goto: BlockGeneric,
          handover :BlockGeneric,
          end: BlockEnd,
};

const edgeTypes = {
   buttonedge: NodeEdge,
};

const edgeOptions = {
  animated: false,
  type: 'smoothstep', 
  markerEnd: {type: MarkerType.ArrowClosed,width: 12,height: 12,color: '#99a1e5'},
  style: {strokeWidth: 2,stroke: '#99a1e5'}
};
 
const minimapStyle = {
  height: 100,
};
export const Builder = () => {
          const {id} = useParams();
          const reactFlowWrapper = useRef(null);
          const [nodes, setNodes, onNodesChange] = useNodesState(null);
          const [edges, setEdges, onEdgesChange] = useEdgesState(null);

          const [isEdgeEditorVisible, setEdgeEditorVisible] = useState(false);
          const [selectedEdge, setSelectedEdge] = useState(null);
          
          const [reactFlowInstance, setReactFlowInstance] = useState(null);

          const [collapsed, setCollapsed] = useState(false);

          const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

          const handleEdgeEditorClose = () => {
            setEdgeEditorVisible(false);
          };

          function hsvToHex(h, s, v) {
              // Verifica si los valores no son números o están fuera de rango
              if (typeof h !== 'number' || typeof s !== 'number' || typeof v !== 'number' ||
              h < 0 || h > 360 || s < 0 || s > 1 || v < 0 || v > 1) {
              console.error('Valores HSV no válidos:', h, s, v);
              return '#000000'; // Valor predeterminado en caso de error
              }

            h = (h % 360 + 360) % 360;
            s = Math.min(1, Math.max(0, s));
            v = Math.min(1, Math.max(0, v));
          
            const c = v * s;
            const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
            const m = v - c;
          
            let r, g, b;
          
            if (0 <= h && h < 60) {
              r = c;
              g = x;
              b = 0;
            } else if (60 <= h && h < 120) {
              r = x;
              g = c;
              b = 0;
            } else if (120 <= h && h < 180) {
              r = 0;
              g = c;
              b = x;
            } else if (180 <= h && h < 240) {
              r = 0;
              g = x;
              b = c;
            } else if (240 <= h && h < 300) {
              r = x;
              g = 0;
              b = c;
            } else {
              r = c;
              g = 0;
              b = x;
            }
          
            const intR = Math.round((r + m) * 255);
            const intG = Math.round((g + m) * 255);
            const intB = Math.round((b + m) * 255);
          
            const hexR = intR.toString(16).padStart(2, '0');
            const hexG = intG.toString(16).padStart(2, '0');
            const hexB = intB.toString(16).padStart(2, '0');
          
            return `#${hexR}${hexG}${hexB}`;
          }

          const handleEdgeEditorSave = (updateData) => {
            const h = updateData.color?.metaColor?.originalInput?.h || 0; // Valor predeterminado 0 si no está definido
            const s = updateData.color?.metaColor?.originalInput?.s || 0;
            const v = updateData.color?.metaColor?.originalInput?.v || 0;
            let color_aux = hsvToHex(h,s,v) || '#99a1e5'
            const updatedEdge = { ...updateData, label: updateData.label,  markerEnd: {type:"arrowclosed",height:12,width:12,color:color_aux},  style:{stroke:color_aux}};
            delete updatedEdge.color
            const updatedEdges = edges.map((item) =>
              item.id === updateData.id ? updatedEdge : item
            );
            console.log("updateData0",updatedEdge)
            setEdges(updatedEdges);
            setEdgeEditorVisible(false);
          };

          const handleEdgeEditorChange = (updateData) => {
            const h = updateData.color?.metaColor?.originalInput?.h || 0; // Valor predeterminado 0 si no está definido
            const s = updateData.color?.metaColor?.originalInput?.s || 0;
            const v = updateData.color?.metaColor?.originalInput?.v || 0;
            let color_aux = hsvToHex(h,s,v) || '#99a1e5'
            const updatedEdge = { ...updateData,  label: updateData.label, markerEnd: {type:"arrowclosed",height:12,width:12,color:color_aux}, style:{stroke:color_aux}};
            delete updatedEdge.color
            const updatedEdges = edges.map((item) =>
              item.id === updateData.id ? updatedEdge : item
            );
            setEdges(updatedEdges);
          };

          //const onEdgeUpdate = (oldEdge, newConnection) => {
          //  console.log("UPDATE")
          //  const updatedEdges = edges.map((edge) => {
          //    if (edge.id === oldEdge.id) {
          //      return { ...edge, color: 'blue' }; // Cambia el color a 'blue' o el color deseado
          //    }
          //    return edge;
          //  });
//
          //  setEdges(updatedEdges);
          //};   

          const onEdgeClick = (event, edge_aux) => {
            event.stopPropagation(); // Evita que el evento de clic se propague al flujo
            const updatedEdge = { ...edge_aux, type: 'step', animated: true };
  
            const updatedEdges = edges.map((item) =>
              item.id === edge_aux.id ? updatedEdge : item
            );
            setEdges(updatedEdges);
            setSelectedEdge(updatedEdge);
            setEdgeEditorVisible(true);

          };

          const onEdgeMouseEnter = (event, edge_aux) => {
            event.stopPropagation();
            const updatedEdge = { ...edge_aux, animated: true};
            const updatedEdges = edges.map((item) =>
              item.id === edge_aux.id ? updatedEdge : item
            );
            setEdges(updatedEdges);
          };

          const onEdgeMouseLeave = (event, edge_aux) => {
            event.stopPropagation();
            const updatedEdge = { ...edge_aux, animated: false };
            const updatedEdges = edges.map((item) =>
              item.id === edge_aux.id ? updatedEdge : item
            );
            setEdges(updatedEdges);
          };
         
          


          const onSave = useCallback(() => {
            if (reactFlowInstance) {
                const  flow = reactFlowInstance.toObject();
               // console.log(flow.nodes)
               // console.log(flow.edges)
               let url = "/bots/nodes/"+id
               API_Request('PUT', url,flow)
               .then((result)=>{
                showSuccessNotification("All data saved!!!")
               })
              
            }
          }, [id, reactFlowInstance]);

          const onDragOver = useCallback((event) => {
                    event.preventDefault();
                    event.dataTransfer.dropEffect = 'move';
          }, []);

          const onNodesDelete = useCallback(
            (deleted) => {
              try {
                        //axios({
                        //  method: 'delete',
                        //  url: GlobalConfig.API_BACKEND+"/flows/node/" + deleted[0].id,
                        //})
                    let url = "bots/nodes/" + deleted[0].id
                    API_Request('DELETE', url)
                    .then(()=>{

                    })    
                    .catch(()=>{
                      console.log("No es posible borra")
                    });           
              } catch(e) {
                console.log("No es posible borra")
              }

              setEdges(
                deleted.reduce((acc, node) => {
                  const incomers = getIncomers(node, nodes, edges);
                  const outgoers = getOutgoers(node, nodes, edges);
                  const connectedEdges = getConnectedEdges([node], edges);
        
                  const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));
        
                  const createdEdges = incomers.flatMap(({ id: source }) =>
                    outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target }))
                  );
        
                  return [...remainingEdges, ...createdEdges];
                }, edges)
              );
            },
            [setEdges, edges, nodes]
          );

          const onDrop =  useCallback(
              (event) => {
                      event.preventDefault();
                
                      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                      const type = event.dataTransfer.getData('application/reactflow');
                      const node  = JSON.parse(event.dataTransfer.getData('application/flow-iteractions'));
                    
                      showSuccessNotification("Element Added!")
                      node.name ='New '+type
                      // check if the dropped element is valid

                      try {
                        if (
                          !type ||
                          (type === 'start' && nodes.find((element) => element.type === 'start')?.type === 'start') ||
                          (type === 'end' && nodes.find((element) => element.type === 'end')?.type === 'end')
                        ) {
                          console.log("NO")
                          return false;
                        }
                        const position = reactFlowInstance.project({
                          x: event.clientX - reactFlowBounds.left,
                          y: event.clientY - reactFlowBounds.top,
                        });

                        const uniqueFacts = new Set();
                        nodes.map((_node) => {
                          if (_node.data.payload && _node.data.payload.fact && _node.data.payload.fact !== null) {
                            uniqueFacts.add(_node.data.payload.fact);
                          }
                          if (_node.data.node_type === "setVariable" && _node.data.payload ) {
                            _node.data.payload.variables.forEach((elem) => {
                              uniqueFacts.add(elem.key);
                            });
                          }
                          return true;
                        });
                        
                        // Si necesitas convertir el conjunto de nuevo a un arreglo, puedes hacerlo así:
                        const facts = [...uniqueFacts].sort();         
                        if (node.fact && node.fact ==="variable") {
                          node.fact ="variable_" + facts.length
                        }          


                                    
                        let newNode = {
                        // id : `new_node_${type}`  + nodes.length,
                          node_type:type,
                          type:type,
                          position,
                          data: { label: `${type} node` ,type: type,payload:node },
                        };
  

                       // axios({
                       //   method: 'post',
                       //   url: GlobalConfig.API_BACKEND+"/bots/node/"+id,
                       //   data: newNode
                       // })
                         let url = "/bots/nodes/"+id
                         API_Request('POST', url,newNode)
                        .then((result) => {
                          newNode.id = '' + result.data.id
                          setNodes((nds) => nds.concat(newNode));
                          
                        })
                        .catch((error) => {
                          console.log(error);
                        });


                      } catch (e) {
                        console.log(e);
                      }





              },
              [id, nodes, reactFlowInstance, setNodes]
          );

          const proOptions = { hideAttribution: true };

          const fitViewOptions = {
                    padding: 0.1,
                    minZoom: 0.2,
                    maxZoom: 2
          };
          useEffect(() => {
                    // Hacer la solicitud fetch para obtener la estructura de nodos y edges
                  
                    let url = "/bots/"+id
                    API_Request('GET', url)
                    .then((response) => {
                      
                              const nodes_aux= [];
                              const edges_aux = [];
                              response.data.forEach((element) => {
                                        element.nodes.forEach((_node)=> {
                                          _node.position = {x:_node.x,y:_node.y}

                                          delete(_node.x);
                                          delete(_node.y);
                                          let payload_aux = []
                                          if(_node.payload & typeof _node.payload === String) {
                                              payload_aux = JSON.parse(_node.payload)
                                          } else {
                                              payload_aux = (_node.payload)
                                          }
                                          nodes_aux.push({type:_node.node_type, id: ''+_node.id+'', position: _node.position, data: { wait:_node.wait,typewritting:_node.typewritting,delay:_node.delay,id:_node.id, name: payload_aux?.name ??  ''  , type: _node.node_type,payload:payload_aux } })
                                          _node.inputs.forEach((element_connection)=> {
                                            edges_aux.push({id:element_connection.id,source:''+_node.id+'',target:''+element_connection.node+'' ,label: element_connection.name, markerEnd: {type: MarkerType.ArrowClosed,width: 12,height: 12,color: (element_connection.color ||'#99a1e5')},style: {strokeWidth: 2,stroke: (element_connection.color ||'#99a1e5')},});
                                          });
                                          _node.outputs.forEach((element_connection) => {
                                            edges_aux.push({id:element_connection.id,source:''+_node.id+'',sourceHandle:element_connection.sourceHandle,target:''+element_connection.node+'', label: element_connection.name,   type: element_connection.type || 'smoothstep', markerEnd: {type: MarkerType.ArrowClosed,width: 12,height: 12,color: (element_connection.sourceHandle && element_connection.sourceHandle ==="false") ?'#ff0000':(element_connection.color ||'#99a1e5')},style: {strokeWidth: 2, stroke: (element_connection.sourceHandle && element_connection.sourceHandle ==="false") ?'#ff0000':(element_connection.color ||'#99a1e5')},});
                                          });
                                        })
                                        

                                        
                              });
                              //console.log(nodes_aux)
                              //console.log(edges_aux)
                              setNodes(nodes_aux)
                              setEdges(edges_aux)
                    })
                    .catch((error) => {
                      console.log(error);
                    });
          }, [id, setEdges, setNodes]);
  if (!nodes) {
    return <Spin spinning={TextTrackCue} style={{ position: 'fixed', right: 16, bottom: 16 }} />;
  }

  return (
    <Layout style={{minHeight: '90vh'}}>
        <Sider  theme='light' width={'130'}  className = {'scroll-pretty'}   style={{overflow: 'auto',height: '90vh',}} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                  <BuilderLibrary></BuilderLibrary>
        </Sider>
        <div style={{ height: '90vh', width: '100%' }}>
            <ReactFlowProvider>
                <ReactFlow ref={reactFlowWrapper}
                          defaultEdgeOptions={edgeOptions}
                          elements={nodes}
                          nodes={nodes}
                          edges={edges}
                          onNodesChange={onNodesChange}
                          onEdgesChange={onEdgesChange}
                         // onEdgeUpdate={onEdgeUpdate}
                          onEdgeDoubleClick={onEdgeClick}
                          onEdgeMouseEnter={onEdgeMouseEnter}
                          onEdgeMouseLeave={onEdgeMouseLeave}
                          onNodesDelete={onNodesDelete}
                          onConnect={onConnect}
                          proOptions={proOptions}
                          style={{ background: '#f7f7f7' }}
                          fitView
                          onInit={setReactFlowInstance}
                          onDrop={onDrop}
                          onDragOver={onDragOver}
                          nodeTypes={nodeTypes}
                          edgeTypes={edgeTypes}
                          snapToGrid={true}
                          connectionLineComponent={ConnectionLine}
                          fitViewOptions={fitViewOptions}
                >
                          <Controls showInteractive={true}>
                                <Button style={{padding:'5px',backgroundColor:'#ffffff'}} type="text" onClick={onSave}><SaveOutlined /></Button>
                          </Controls>
                          <MiniMap style={minimapStyle} zoomable pannable />
                          <Background variant="lines" gap={30} size={5} />
                        
                </ReactFlow>
            </ReactFlowProvider>
            {isEdgeEditorVisible && 
            <EdgeEditor
                  onSave={handleEdgeEditorSave}
                  onClose={handleEdgeEditorClose}
                  onChange={handleEdgeEditorChange}
                  data={selectedEdge}
            /> }
        </div>
    </Layout>
  )
};