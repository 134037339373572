import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Space, Row, Col, Tooltip, Layout, List, Modal, Radio,  Slider,Select } from 'antd';
import * as Icons from '@ant-design/icons';
import './ChatWindow.css'
import Sider from 'antd/es/layout/Sider';
import { formatSpanishDate} from '../Services/APIService'
import MessageCompomnent from './MessageComponent'
import { FormSecondaryWindow } from './FormSecondaryWindow';


const ChatWindow = ({ userData, session ,customer,onSave, onChange,onReceived, onEvent}) => {

          const [collapsed, setCollapsed] = useState(JSON.parse(localStorage.getItem("CollapseVariablePanel") )|| true);
          const [message, setMessage] = useState('');
          const [customerVariables, setcustomerVariables] = useState();
          const [messages, setMessages] = useState([]);
          const [sessionVariables, setSessionVariables] = useState({});
          const [isFormOnlineBuilderVisible, setFormOnlineBuilderVisible] = useState(false);
          const [modalVisible, setModalVisible] = useState(false);
          const [modalAssignBotVisible, setmodalAssignBotVisible] = useState(false);
          const [assignBotList, setassignBotList] = useState([]);
          const [assignBotSelected, setassignBotSelected] = useState(null);
          const [modalData, setModalData] = useState({ title: '', url: '' });


          const handle_SelectAssignBotChange =(_value)=> {
            setassignBotSelected(_value)
          }


          const openModal = (_context) => {
            switch(_context) {
              case "document":
                setModalVisible(true);      
              break;
              case "assignBot":
                // Aplanar el arreglo para obtener una lista de bots
                // Utilizar map y reduce para aplanar el arreglo
                const flattenedBots = userData.Accounts.reduce((acc, curr) => {
                  return acc.concat(curr.Bots);
                }, []);

                // Transformar la lista para el formato de Ant Design
                const selectOptions = flattenedBots.map(bot => ({
                  label: bot.name,
                  value: bot.id
                }));

                setassignBotList(selectOptions)

                setmodalAssignBotVisible(true);
              break;
                default:
            }
          };

          const closeModal = (_context) => {
            switch(_context) {
              case "document":
                setModalVisible(false);
                setModalData({ title: '', url: '' }); // Limpiar los valores ingresados                
              break;
              case "assignBot":
                setmodalAssignBotVisible(false);
              break;
                default:
                  setModalVisible(false);
            }
          };

          const chatContainerRef = useRef(null);

          useEffect(() => {
            const sessionVariablesCopy = { ...sessionVariables };

            if (!sessionVariablesCopy[session.uid]) {
              sessionVariablesCopy[session.uid] = Object.entries(session.variables || {}).map(([key, value]) => ({
                key,
                value: typeof value === 'object' ? JSON.stringify(value) : value,
              }));
              setSessionVariables(sessionVariablesCopy);
            }
          }, [session, sessionVariables]);

          useEffect(() => {
            if (onReceived) {
              setMessages(onReceived.messages);

                // Scroll automático al recibir un nuevo mensaje
              if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
              }
            }
          }, [onReceived]);

          useEffect(()=>{
            if (customer){
              setcustomerVariables(customer)
            }
          },[customer])

          useEffect(() => {
                      // Scroll automático al recibir un nuevo mensaje
                      if (chatContainerRef.current) {
                                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                      }
          }, [messages]);

          const updateSessionVariables = (sessionId,_variable) => {
            session.variables.status =" cx "
              setSessionVariables((prev) => {
                  const existingVariables = prev[sessionId] || [];
                  let updatedVariables = [...existingVariables];

                  // Verificar si ya existe una variable "agent"
                  const agentVariableIndex = updatedVariables.findIndex((variable) => variable.key === _variable.key);

                  if (agentVariableIndex !== -1) {
                      // Si existe, actualizar el valor
                      updatedVariables[agentVariableIndex] = { ...updatedVariables[agentVariableIndex], value: _variable.value };
                  } else {
                      // Si no existe, agregar una nueva variable "agent"
                      updatedVariables.push(_variable);
                  }

                  return {
                      ...prev,
                      [sessionId]: updatedVariables,
                  };
              });
          };

          const handleInputChange = event => {
                    setMessage(event.target.value);
          };

          const sendDocument = () => {
            if (modalData.title && modalData.url) {
                let url_aux = modalData.url
                let message_aux = { 
                  id: messages.length + 1, 
                  event:'file', 
                  type:'message', 
                  payload:{title:modalData.title, url: url_aux}, 
                  author: 'agent' ,
                  authorName: userData.username,
                  timestamp:  Math.round(Date.now()/1000)
                }
                setMessages(prevMessages => [...prevMessages, message_aux]);
                onSave(session,message_aux);
                setMessage('');     
                // Actualizar sessionVariables para agregar un nuevo elemento para el sessionId actual
                updateSessionVariables(session.uid,{ key: 'agent', value: userData.username})
                closeModal();

            }
          }


          const assignBot = () => {
              onEvent(session,assignBotSelected);
              closeModal('assignBot') 
          }

          const sendMessage = () => {
                    if (message!== null && message.trim() !== '') {
                              let message_aux = { 
                                id: messages.length + 1,
                                  event:'text', 
                                  type:'message',
                                  payload:{'text': message}, 
                                  author: 'agent' ,
                                  authorName: userData.username,
                                  timestamp:  Math.round(Date.now()/1000)
                                }
                              // Enviar mensaje (aquí debes emitir el mensaje a través del socket)
                              setMessages(prevMessages => [...prevMessages, message_aux]);
                              onSave(session,message_aux);
                              setMessage('');
                              // Actualizar sessionVariables para agregar un nuevo elemento para el sessionId actual
                              updateSessionVariables(session.uid,{ key: 'agent', value: userData.username})
                    }
          };

          const handleInputKeyPress = event => {
                if (event.key === 'Enter') {
                          event.preventDefault(); 
                          sendMessage();
                          setMessage('');
                }
          };

          const handleCollapseVariablePanel= (value) => {
                localStorage.setItem("CollapseVariablePanel",value)
                setCollapsed(value)
          }

          const handleCopyToClipboard = (value) => {
                const textArea = document.createElement('textarea');
                textArea.value = value;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
          };

          const orderSessionVariables = (variables) => {
            // Define the preferred order of keys
            const preferredOrder = ["agent", "status"];
            
            // Extract the keys from variables
            const variableKeys = variables.map(variable => variable.key);

            // Sort the keys based on the preferred order
            const sortedKeys = variableKeys.sort((a, b) => {
                const indexA = preferredOrder.indexOf(a);
                const indexB = preferredOrder.indexOf(b);
                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB;
                } else if (indexA !== -1) {
                    return -1;
                } else if (indexB !== -1) {
                    return 1;
                }
                return 0;
            });

            // Use the sorted keys to reorder the variables
            const orderedVariables = sortedKeys.map(key => variables.find(variable => variable.key === key));

            const filteredArray = orderedVariables.filter(item => (
               item.key !== "publicId"
              && item.key !== "idAccount"
              && item.key !== "customerId"
              && item.key !== "middleware_response"
              && item.key !== "middleware_type_response"
              ));
            return filteredArray;
          };


        const handleChangeSession = (e) => {
          updateSessionVariables(session.uid,{ key: 'status', value: e.target.value})
          onChange(session.uid,{context:"session",id:session.uid,data:{key:"status",value:e.target.value}});
        };

        const handle_CustomerRank= (value)=> {
          const customerVariablesCopy = { ...customerVariables };
          customerVariablesCopy.rank = value
          setcustomerVariables(customerVariablesCopy);
          if(customer.publicId!==undefined){
            onChange(session.variables.customerId || 0,{context:"customer",id:session.variables.customerId,data:{rank:value}});
          }
        }
      
        const handle_FormOnlineBuilderSave= (updateData) => {
          onChange(session.variables.customerId || 0,{context:"customer",id:session.variables.customerId,data:updateData.payload});
          setFormOnlineBuilderVisible(false);

        }
        const handle_FormOnlineBuilderClose= () => {
          setFormOnlineBuilderVisible(false);
        }

        const handle_setFormOnlineBuilderVisible = () => {
          setFormOnlineBuilderVisible(true)
        }

return (
  <Row>
    <Col span={24}>
      <Row>
        <Col span={18} style={{fontSize:'22px',lineHeight:'22px'}}>
            <Row>
              <Col span={6}>
                <Tooltip title={'Edit customer'}>
                  <Icons.UserOutlined onClick={handle_setFormOnlineBuilderVisible} style={{fontSize:'22px'}} /> {session?.uid.split("_")[1]}
                </Tooltip>
              </Col>
              <Col span={18}>
                  <Slider
                  onAfterChange={(e) => {
                      handle_CustomerRank(e);
                  }}
                  trackStyle={{ color: '#f05' }}
                  className={'slider_rank'}
                  marks={{
                    0: { style: { color: '#000' }, label: <span>Not Interested</span> },
                    25: { style: { color: '#b8ae84' }, label: <strong>Considered</strong> },
                    50: { style: { color: '#c69e02' }, label: <strong>Interested</strong> },
                    75: { style: { color: '#c65e02' }, label: <strong>Lead</strong> },
                    100: { style: { color: '#0a0' }, label: <strong>Customer</strong> },
                  }}
                  min={0}
                  max={100}
                  step={5}
                  value={customerVariables?.rank} 
                  tooltip={{ open: true, placement:'top'}}
              />              

              </Col>
            </Row>
        </Col>
        <Col span={6} style={{textAlign:'end',lineHeight:'22px'}}>
             {session?.uid.split("_")[0]} <Icons.LoginOutlined style={{fontSize:'22px'}}  />
        </Col>
      </Row>
    </Col>
    <Col span={12} style={{textAlign:'start',lineHeight:'22px'}}>
        <Icons.NotificationOutlined ></Icons.NotificationOutlined> {formatSpanishDate(messages[messages.length - 1]?.updatedAt)||'Loading'}
    </Col>
    <Col span={12} style={{textAlign:'end',lineHeight:'22px'}}>
     {messages.length || 0} messages <Icons.HistoryOutlined ></Icons.HistoryOutlined>
    </Col>
        <Layout>
            <Layout className="chat-window">
                <Layout className="chat-messages" ref={chatContainerRef}>

                    <Row style={{'display':'flex'}}>
 
                        {messages.filter((item)=>item.type !=='session').map((message)=> (
                                    <Col
                                      key={'col' + message.id+message.timestamp+''+Math.round(Date.now()/1000)}
                                      className={`ant-col ant-col-24 bubble bubble_${(message.author === 'agent' || message.author === 'myself'  || message.author === 'bot' || message.author === 'system' || message.author === 'IA') ? 'sent' : 'received'}`}
                                    >
                                        <MessageCompomnent  key={'col' + message.id+message.timestamp+''+Math.round(Date.now()/1000)} message={message}></MessageCompomnent>
                                    </Col>
                         ))}  
                    </Row>
                </Layout>
                <Layout>

                          <Space.Compact style={{ width: '95%' ,marginBottom:'10px',border:'1px solid #d9d9d9',margin:'10px',marginRight:'30px',borderRadius:'10px 10px 10px 10px',}}>
                                    
                                    <Input.TextArea
                                    style={{borderRadius:'10px 0px 0px 10px',border:'0px solid #d9d9d9',width:'100%'}}
                                              autoSize={{ minRows:1, maxRows: 3 }} 
                                              prefix={<Icons.MessageOutlined />} 
                                              value={message}
                                              onChange={handleInputChange}
                                              onKeyPress={handleInputKeyPress}
                                              placeholder="Your message..."
                                              onPressEnter={handleInputKeyPress}
                                    />
                                    <Tooltip title="Send document">
                                        <Button onClick={()=>openModal('document')} style={{border:'0px solid #d9d9d9',height: 'auto',borderRadius:'0px 0px 0px 0px',borderLeft:'0px',borderRight:'0px',borderTop:'1px',borderBottom:'1px',backgroundColor:'white'}}><Icons.FileAddOutlined /> </Button>    
                                    </Tooltip>
                                    <Tooltip title="Asign Bot">
                                        <Button onClick={()=>openModal('assignBot')} style={{border:'0px solid #d9d9d9',height: 'auto',borderRadius:'0px 0px 0px 0px',borderLeft:'0px',borderRight:'0px',borderTop:'1px',borderBottom:'1px',backgroundColor:'white'}}><Icons.BugOutlined  /> </Button>    
                                    </Tooltip>                                    
                                    <Tooltip title="Send message">
                                        <Button onClick={sendMessage} style={{border:'0px solid #d9d9d9',height: 'auto',borderRadius:'0px 10px 10px 0px',borderLeft:'0px',borderRight:'1px',borderTop:'1px',borderBottom:'1px',backgroundColor:'white'}}><Icons.SendOutlined /> </Button>     
                                    </Tooltip>
                                    
                          </Space.Compact>
                </Layout>

            </Layout>
            <Sider collapsedWidth={50} className='chat-session-variables'  trigger={null}  theme='light' width={'200'} reverseArrow={true}  style={{overflow: 'auto',paddingLeft:'10px'}} collapsible collapsed={collapsed} onCollapse={(value) => handleCollapseVariablePanel(value)}>
                      <List
                                header={<strong> 
                                  <Tooltip title={'Session data'}>
                                    <Button
                                      type="text"
                                      icon={collapsed ? <Icons.MenuFoldOutlined /> : <Icons.MenuUnfoldOutlined />}
                                      onClick={() => setCollapsed(!collapsed)}
                                      style={{
                                        fontSize: '16px',
                                        width: 32,
                                        height: 32,
                                      }}
                                    /> 
                                  </Tooltip>  

                              {!collapsed && <span style={{ marginLeft: '8px' }}>Session Data</span>}
                              </strong>}
                                dataSource={orderSessionVariables(sessionVariables[session.uid]|| []) }
                                renderItem={item => (
                                  !collapsed && (

                                  (item.key!=="status" &&item.key!=="agent"  ) ?
                                          <List.Item id={'prinp'+ item.key} className='chat-session-variable' key={'prinp'+ item.key} >
                                                   <List.Item.Meta
                                                        key={'sub'+ item.key} 
                                                        avatar={<Icons.FunctionOutlined />}
                                                        title={item.key||''}
                                                    />
                                                             {item.value}{<Icons.CopyOutlined style={{ marginRight: '8px',float:'right', cursor: 'pointer' }}  onClick={() => handleCopyToClipboard(item.value)}/>}
                                          </List.Item>
                                          :
                                          <List.Item id={'prinp'+ item.key}  key={'prinp'+ item.key} >
                                                   <List.Item.Meta
                                                        key={'sub'+ item.key} 
                                                        avatar={
                                                          (item.key==="status")?
                                                          <Tooltip title={`Status  ${item.value}`}>
                                                              <Icons.CommentOutlined  style={{fontSize:'20px',color: (item.value==="open")?'#ff0000':(item.value==="processing")?'#b34914':'#00aa00' }} />
                                                          </Tooltip>
                                                          :
                                                          <Tooltip title={`manage by ${item.value.split("@")[0]}`}><Icons.CustomerServiceOutlined style={{fontSize:'20px'}}/></Tooltip>
                                                        }
                                                        title={(item.key==="status")?'':item.value.split("@")[0]}
                                                    />
                                                              {(item.key==="status") && <>
                                                              <Radio.Group size='small' value={item.value} onChange={handleChangeSession}>
                                                                <Radio.Button value="open">      <Tooltip title="Open"><Icons.FolderOpenOutlined   style={{fontSize:'20px',color:'#ff0000' }}/></Tooltip></Radio.Button>
                                                                <Radio.Button value="processing"><Tooltip title="Processing"><Icons.FolderViewOutlined  style={{fontSize:'20px',color:'#b34914' }}/></Tooltip></Radio.Button>
                                                                <Radio.Button value="closed">    <Tooltip title="Closed"><Icons.FolderOutlined  style={{fontSize:'20px',color:'#00aa00' }}/></Tooltip></Radio.Button>
                                                              </Radio.Group>
                                                              </>}

                                          </List.Item>
                                        ))}
                      />
            </Sider>
            { (isFormOnlineBuilderVisible && customer.id )&& <FormSecondaryWindow
                      title ={"Customer details " +customer.id}
                      width= {'40%'}
                      onSave={handle_FormOnlineBuilderSave}
                      onClose={handle_FormOnlineBuilderClose}
                      data={{entity:"customers",fieldsForm:[
                                                                {
                                                                    "name": "name"
                                                                },                        
                                                                {
                                                                    "name": "phone"
                                                                },
                                                                {
                                                                    "name": "email"
                                                                },
                                                                {
                                                                  "name": "attributes"
                                                               },
                    ],payload:{...customer,uid:session?.uid},customerId:session?.uid.split("_")[1]}}
                    />}

        </Layout>
        <Modal
          title="Send Document"
          open={modalVisible}
          onOk={sendDocument}
          onCancel={()=>closeModal('document')}
        >
          <Row>
            <Col span={6}>
                  <Input
                    placeholder="Name"
                    value={modalData.title}
                    onChange={e => setModalData({ ...modalData, title: e.target.value })}
                  />
            </Col>
            <Col span={18}>
                  <Input
                    placeholder="URL"
                    value={modalData.url}
                    onChange={e => setModalData({ ...modalData, url: e.target.value })}
                  />              
            </Col>
          </Row>
        </Modal>
        <Modal
          title="Select bot to assign"
          open={modalAssignBotVisible}
          onOk={assignBot}
          onCancel={()=>closeModal('assignBot')}
        >
          <Select onChange={handle_SelectAssignBotChange} style={{width:200}} options={assignBotList}></Select>
        </Modal>
  </Row>
)
};

export default ChatWindow;
