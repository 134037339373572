import React, { useEffect, useState } from 'react';
import { Button,Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { API_Request } from '../Services/APIService';
import * as Icons from '@ant-design/icons';
import DataTable from '../Components/DataTable';
import { useNavigate } from 'react-router-dom';
import * as notificationService from '../Services/notifications';
import { Spin } from 'antd';


const dataTable_params = {
  "entity": "sessions",
  "main": "uid",
  "key": "id",
  "create": false,
  "rows": 10,
  "fieldsForm":[
    {"name":"id","label":"id","required":true},
    {"name":"uid","label":"Session Id","column":true},
    {"name":"type","label":"Session type","column":true,"width":"150"}, 
    {"name":"current_node"},
    {"name":"updatedAt"},
    {"name":"createdAt"}
  ],
  "actions": [
    {
      icon: <Icons.SettingOutlined  style={{ fontSize: '20px'}}  />,
      link: "sessions",
      title:"Settings"
    }
  ]
};

const dataTable_params_history = {
  "entity": "sessions",
  "main": "uid",
  "key": "uid",
  "rows": 100,
  "create": false,
  "fieldsForm":[
    {"name":"type","label":"id","required":true},
    {"name":"author","label":"Session Id"},
    {"name":"event","label":"Session type","column":true,"width":"150"}, 
    {"name":"author","column":true,},
    {"name":"state","column":true,},
    {"name":"updatedAt"},
    {"name":"payload"},
    {"name":"variables"}
  ]
};



const SessionsPage = () => {

  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const entity = "Sessions";
  const navigate = useNavigate();

  const handleDelete = () => {
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = (id) => {
    // Aquí puedes realizar la lógica de borrado
    API_Request('DELETE', entity+"/"+data.id)
    .then((response) => {
      notificationService.showSuccessNotification('Data Deleted!');
    })
    .catch((error) => {
      notificationService.showErrorNotification(error.message);
      console.log(error);
    })
    .finally(()=>{
      setDeleteModalVisible(false);
        navigate(-1);
    })

  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };



  useEffect(() => {
    setLoading(true);
    API_Request('GET',  id ? '/'+entity.toLowerCase()+'/'+id : '/'+entity.toLowerCase())
    .then((result)=>{
      setData(result.data);
    })
    .catch((error)=>{
      console.log(error);
    })
    .finally(()=>{
      setLoading(false);
    })
  }, [id]);
  if (data === undefined  || (data.length < 1)) {
    return <Spin spinning={TextTrackCue} style={{ position: 'fixed', right: 16, bottom: 16 }} />
  }
  return (
    (id !==undefined) ?
    <>
        <h2>Details</h2>
        {data.history  && <DataTable loading={loading} data={data.history} params={dataTable_params_history}/>}
        <div style={{ display: 'flex', justifyContent: 'center' ,marginTop:20}}>
                                  <Button type="primary" danger style={{justifySelf:'end'}} onClick={()=>handleDelete(id)}>
                                      <Icons.CloseOutlined /> Delete
                                  </Button>   

        </div>
        <Modal
                                        title="Confirm Delete"
                                        open={deleteModalVisible}
                                        onOk={handleDeleteConfirm}
                                        onCancel={handleDeleteCancel}
                              >
                                        <p>Are you sure you want to delete ?</p>
        </Modal>
    </>
     :
     <>
     <h1>{entity}</h1>
     <DataTable loading={loading} data={data} params={dataTable_params}/>
     </>
  );

};

export default SessionsPage;
