import { Layout, List, Badge, Switch, Tooltip, Segmented, notification, Spin, Row, Col, Input, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { useState, useEffect, useContext,useRef } from 'react';
import io from 'socket.io-client';
import './../Components/ChatWindow.css'
import ChatWindow from './../Components/ChatWindow';
import TimelineWindow from './../Components/TimelineWindow';
import { API_Request,formatSpanishDate } from './../Services/APIService';
import {GlobalConfig} from './../App'
import { AuthContext } from './../Services/AuthService';
import { showSuccessNotification } from '../Services/notifications';

const ChatsPage = () => {
          const [sessions, setSessions] = useState([]);
          const [loading, setLoading] = useState(true);
          const [currentPageSessions, setCurrentPageSessions] = useState(1);
          const [selectedSession, setSelectedSession] = useState(null);
          const [selectedCustomer, setselectedCustomer] = useState(null);
          const [socket, setSocket] = useState(null); // Agrega una nueva variable de estado para el socket
        //  const [ReceivedMessage, setReceivedMessage] = useState([]); // Agregar el estado para el mensaje recibido
        //  const [ReceivedNode, setReceivedNode] = useState([]);
          const [pendingMessageCountsTotal, setpendingMessageCountsTotal] = useState(0);
          const [pendingMessageCounts, setPendingMessageCounts] = useState({});
          const [LastMessageDate, setLastMessageDate] = useState({});
          const [LastMessage, setLastMessage] = useState({});
          const [Recibidos, setRecibidos] = useState({});
          const [selectedTab, setSelectedTab] = useState('allMessages');
          const scrollContainerRef = useRef(null);
          const [orangeIconSessions, setOrangeIconSessions] = useState([]);
          const [sounds, setsounds] = useState(false);
          //const [notifications, setnotifications] = useState(false);
          const [searchTerm, setSearchTerm] = useState('');
          const { userData } = useContext(AuthContext);

          const queryParams = {
            token: localStorage.getItem('apiKey')
          };

          const handleIconDoubleClick = (session) => {
              // Toggle the session's status in the orangeIconSessions array
              const updatedOrangeIconSessions = orangeIconSessions.includes(session.uid)
                  ? orangeIconSessions.filter(uid => uid !== session.uid)
                  : [...orangeIconSessions, session.uid];
              setOrangeIconSessions(updatedOrangeIconSessions);
          };

          const handleTabChange = (activeKey) => {
            setLoading(true)
            setSelectedTab(activeKey); // Debes definir setSelectedTab en tu estado
            setLoading(false)
          };


          const handleSound =(value) => {
            window.localStorage.setItem("sound",value)
            setsounds(value)
          }

          const handle_Event = (session,bot) => {
            if (session && bot) {
              API_Request("GET","sessions/assignBot/"+session.uid+"/"+bot)
              .then((result)=>{
                showSuccessNotification("Bot Assigned!!!")
                
              })
            }
          }

          const handle_Change =(session,updatedData) => {
            if (updatedData.context ==="customer") {

                    let customerAux = {...selectedCustomer,...updatedData.data}
                    //let body_aux = {[updatedData.data.key]:updatedData.data.value}
                    API_Request("PUT","customers/" + updatedData.id,updatedData.data)
                    .then((result)=>{
                      setselectedCustomer(customerAux)
                      showSuccessNotification("Customer updated!!!")
                      
                    })
                    
            } else {
                    const sessionAux = {...selectedSession}
                    sessionAux.variables.status = updatedData.data.value
                    API_Request("PUT","sessions/" + updatedData.id,{variables:sessionAux.variables})
                    .then((result)=>{
                      showSuccessNotification("Session updated!!!")

                      setSessions(prev => {
                        return prev.map(session => {
                            if (session.uid === sessionAux.uid) {
                                const updatedSession = { ...session };
                                return updatedSession;
                            }
                            return session;
                        });
                    });   


                    })
            }
          }

          const handle_Save = (session,updatedData) => {

                 const message = {
                      id:''+Math.round(Date.now()/1000),
                      payload: updatedData.payload,
                      timestamp: Math.round(Date.now()/1000),
                      type: updatedData.type,
                      event:  updatedData.event,
                      sender : {
                        name:session.variables.name,
                        phone: selectedSession.variables.phone||'0'
                      },
                      phone:selectedSession.variables.phone||'0',
                      idflow:session.idflow,
                      uid: session.uid,
                      variables:session.variables,
                      authorName:updatedData.authorName,
                      author: 'agent'
                  }

                 // Enviar un mensaje al servidor a través del socket

                 
                 setRecibidos(prev => {
                  const updatedRecibidos = { ...prev };
                  if (!updatedRecibidos[message.uid]) {
                      updatedRecibidos[message.uid] = [];
                  }
                  updatedRecibidos[message.uid].push(message);
                  return updatedRecibidos;
                });
                socket.emit('message', message);
          } 

          const playNotificationSound = () => {
            let sound = (window.localStorage.getItem("sound")==="false")?false:true;
            if (sound) {
               let audio_aux = (GlobalConfig.NODE_ENV==='DEV')?"/notification.mp3":(GlobalConfig.API_BACKEND.replace("/api","") +"/notification.mp3")
              let audio = new Audio(audio_aux)
              audio.play();              
            }
          };

          const loadSessions = async (page) => {
            setLoading(true)
            try {
              const response = await API_Request('GET', `/sessions?handover=true&page=${page}`);
              
              if (response.data.length >0) {
                setSessions(response.data)
                response.data.map((data_aux)=> {
                  setRecibidos(prev => {
                    return {
                      ...prev,
                      [data_aux.uid]: data_aux.history,
                    };
                  });     
                  return true
                })
              }

              setLoading(false)

              return response.data;
            } catch (error) {
              console.error('Error fetching sessions:', error);
              return [];
            }
          };


          useEffect(() => {
                    window.localStorage.setItem("sound",false)
                    // Conectarse al servidor de sockets
                    const socket_url_aux = GlobalConfig.API_BACKEND.replace("/api","")
                    const socket = io(socket_url_aux, { forceNew: true ,  query: queryParams}); 
                    setSocket(socket); // Guardar el socket en el estado

                    // Eventos para recibir mensajes del servidor
                    socket.on('message', (data) => {

                          let data_aux = typeof data === "string" ? JSON.parse(data) : data;

                          setPendingMessageCounts(prev => {return {...prev,[data_aux.uid]: (prev[data_aux.uid] || 0) + 1}});
                          setLastMessageDate(prev =>      {return {...prev,[data_aux.uid]: Math.round(Date.now()/1000)}});    
                          setLastMessage(prev =>          {return {...prev,[data_aux.uid]: data_aux.payload?.text ||data_aux.payload?.msg || data_aux.initial?.payload?.text || 'message received'}});    

                          setRecibidos(prev => {
                            const updatedRecibidos = { ...prev };
                            if (!updatedRecibidos[data_aux.uid]) {
                                updatedRecibidos[data_aux.uid] = [];
                            }
                            updatedRecibidos[data_aux.uid].push(data_aux);
                            return updatedRecibidos;
                          });

                          // Encuentra la sesión correspondiente en el estado sesiones
                          setSessions(prev => {
                              return prev.map(session => {
                                  if (session.uid === data_aux.uid) {
                                      // Asegúrate de que history sea un array y agrega el nuevo mensaje
                                      const updatedSession = { ...session };
                                      updatedSession.history = updatedSession.history || []; // Asegurarse de que history sea un array
                                      updatedSession.history.push(data_aux);

                                      return updatedSession;
                                  }
                                  return session;
                              });
                          });
                          setpendingMessageCountsTotal(prev => prev + 1);
                          playNotificationSound();
                          showNotification();
                          //console.log("socket recibe message",data_aux, "session elegida",selectedSession)

                    });

                    socket.on('executeNode', (data) => {
                          let data_aux = typeof data === "string" ? JSON.parse(data) : data;
                          setPendingMessageCounts(prev => {
                            return {
                              ...prev,
                              [data_aux.uid]: (prev[data_aux.uid] || 0) + 1,
                            };
                          });
            
                          setLastMessageDate(prev => {
                            return {
                              ...prev,
                              [data_aux.uid]: Math.round(Date.now()/1000),
                            };
                          });    
            
                          setLastMessage(prev => {
                            return {
                              ...prev,
                              [data_aux.uid]: data_aux.initial?.payload?.text || 'node executed',
                            };
                          });    
                          setRecibidos(prev => {
                            const updatedRecibidos = { ...prev };
                            if (!updatedRecibidos[data_aux.uid]) {
                                updatedRecibidos[data_aux.uid] = [];
                            }
                            updatedRecibidos[data_aux.uid].push(data_aux);
                            return updatedRecibidos;
                          });       
                          // Encuentra la sesión correspondiente en el estado sesiones
                          setSessions(prev => {
                            return prev.map(session => {
                                if (session.uid === data_aux.uid) {
                                    // Asegúrate de que history sea un array y agrega el nuevo mensaje
                                    const updatedSession = { ...session };
                                    updatedSession.history = updatedSession.history || []; // Asegurarse de que history sea un array
                                    updatedSession.history.push(data_aux);

                                    return updatedSession;
                                }
                                return session;
                            });
                        });                          
                          
                          playNotificationSound();
                          //console.log("socket recibe executeNode",data_aux, "session elegida",selectedSession)
                    });

                    socket.on('session', (newSession) => {
                      
                          let data_aux = JSON.parse(newSession)
                          const mymessages = userData.Flows.find(item => item.FlowId === data_aux.idflow);

                          if (mymessages) {
                                socket.emit("registerTo", data_aux.uid);
                                playNotificationSound();
                                setLastMessageDate(prev => {
                                  const sessionUid = data_aux.uid;
                                  return {
                                    ...prev,
                                    [sessionUid]: Math.round(Date.now()/1000),
                                  };
                                });    
                                setLastMessage(prev => {
                                  return {
                                    ...prev,
                                    [data_aux.uid]:  'Session started!',
                                  };
                                });                       
                  
                                setSessions((prev) => {
                                  // Verificar si la sesión ya existe en el array
                                  const existingSessionIndex = prev.findIndex(session => session.uid === data_aux.uid);
                                  data_aux.updatedAt = Math.round(Date.now()/1000)
                                  // Si la sesión ya existe, moverla al principio del array
                                  if (existingSessionIndex !== -1) {
                                    const updatedSessions = [...prev];
                                    updatedSessions.splice(existingSessionIndex, 1); // Eliminar la sesión existente
                                    updatedSessions.unshift(data_aux); // Agregar la nueva sesión al principio
                                    return updatedSessions;
                                  } else {
                                    // Si la sesión no existe, agregarla al principio
                                    data_aux.history = []
                                    return [data_aux, ...prev];
                                  }
                                });

                          }

                    });
                    
                    socket.emit("check-in", localStorage.getItem('apiKey'));

                    userData.Accounts.map((account)=>{
                      socket.emit("registerTo", "sessions_"+account.id);
                      return true
                    })

                    socket.on('check-out', (data) => {
                          data.map((room) => {
                            //console.log("check-out registerTo",room)
                            socket.emit("registerTo", room);
                            return true
                          });
                    });               
                
                    loadSessions(currentPageSessions)

                    // Cerrar la conexión del socket cuando el componente se desmonte
                    return () => {
                      socket.disconnect();
                    };

          }, []);


          useEffect(() => {
            const handleScroll = () => {
              const scrollContainer = scrollContainerRef.current;
              if (!scrollContainer) return; // Asegúrate de que la referencia exista
          
              const { scrollTop, clientHeight, scrollHeight } = scrollContainer;

              if (scrollTop + clientHeight >= scrollHeight - 500) {
                // Cuando el usuario está a 100px del final del contenedor, carga más sesiones
                const loadMoreSessions = async () => {
                  setLoading(true)
                  const nextPage = currentPageSessions + 1;
                  const newSessions = await loadSessions(nextPage);
                  if (newSessions.length > 0) {
                    setCurrentPageSessions(nextPage);
                    setSessions([...sessions, ...newSessions]);
                  }
                  setLoading(false)
                };
                loadMoreSessions();
              }
            };
          
            // Agrega el evento de scroll al contenedor
            const scrollContainer = scrollContainerRef.current;
            if (scrollContainer) {
              scrollContainer.addEventListener('scroll', handleScroll);
            }
          
            // Remueve el evento de scroll al desmontar el componente
            return () => {
              if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
              }
            };
          }, [sessions, currentPageSessions]);


          async function  showNotification () {
            let messageIcon_aux = (GlobalConfig.NODE_ENV==='DEV')?"/message.png":(GlobalConfig.API_BACKEND.replace("/api","") +"/message.png")

            var options = {
              body: 'New Message',
              icon: messageIcon_aux,
              dir: 'ltr',
            };
            if(!("Notification" in window)) {
              alert("Browser does not support notification")
            } else if (Notification.permission ==="granted") {
                new Notification('Interactions', options);
            } else if (notification.permission !=="denied") {
              await Notification.requestPermission()
              .then((permission)=>{
                if (permission==="granted") {
                   new Notification('Interactions', options);
                }
              })
            }
        
            new Notification('Interactions', options);
          }
          

          const handleSessionClick = (session) => {
                if (session.variables?.customerId) {
                    API_Request('GET',  '/customers/'+session.variables?.customerId)
                    .then((result)=> {
                      setselectedCustomer(result.data[0]);
                    })
                    .catch((e)=>{
                      console.log("e",e)
                    })
                }

                setSelectedSession(session);


                    // Marcar todos los mensajes de la sesión como leídos
                setPendingMessageCounts(prev => ({
                  ...prev,
                  [session.uid]: 0, // Marcar como leídos al hacer clic en la sesión
                }));
          };

          const totalAllMessages = sessions.length;
         // const totalNonAttended = sessions.filter(session => (session.variables.agent === null || session.variables.agent === undefined)).length;
         // const totalByAgent = sessions.filter(session => (session.last_customer_message !== null && session.variables.agent === userData.username)).length;
          return(
                 <Layout>
                      {(loading) && <Spin spinning={TextTrackCue} style={{ position: 'fixed', right: 16, bottom: 16 }} />}
                      <Row>
                            <Col span={8}>
                                    <Row>
                                      <Col span={6}>
                                          <Switch size='small'  checkedChildren={<Icons.SoundOutlined ></Icons.SoundOutlined>} unCheckedChildren={<Icons.CloseOutlined ></Icons.CloseOutlined>}  onChange={(checked)=>{handleSound(checked)}} defaultChecked={sounds}> </Switch> Sounds
                                      </Col>
                                      <Col span={18}>
                                          <Segmented defaultValue={selectedTab} size="small" options={
                                          [
                                            {
                                            label: (
                                              <div key ={1} style={{ padding: 2,paddingTop:8 ,paddingBottom:8 }}>
                                                <div>My!</div>
                                              </div>
                                            ),
                                            value: 'myMessages',
                                          },
                                          {
                                            label: (
                                              <div onClick={()=>{setpendingMessageCountsTotal(0)}}style={{ padding: 2,paddingTop:8 ,paddingBottom:8 }}>
                                                      <Badge status="success" count={pendingMessageCountsTotal} color={pendingMessageCountsTotal >= 0 && pendingMessageCountsTotal < 5 ? '#52c41a' : pendingMessageCountsTotal >= 5 && pendingMessageCountsTotal < 10 ? '#faad14' : '#f5222d' } >Pending</Badge>
                                              </div>
                                              
                                            ),
                                            value: 'NonAttended',
                                          },
                                          {
                                            label: (
                                              <div key ={3} style={{ padding: 2,paddingTop:8,paddingBottom:8  }}>
                                                <div>All!!!</div>
                                              </div>
                                            ),
                                            value: 'allMessages',
                                          }                                                         
                                        ]
                                          } 
                                            onChange={handleTabChange}
                                          /> {totalAllMessages}                                       
                                      </Col>                                      
                                    </Row> 

                                    <Space.Compact style={{ width: '95%' ,marginTop:'10px', marginBottom:'10px', border:'1px solid #d9d9d9',marginRight:'30px',borderRadius:'10px 10px 10px 10px',}}>
                                              
                                              <Input.TextArea
                                                  style={{borderRadius:'10px 10px 10px 10px',border:'0px solid #d9d9d9',width:'100%'}}
                                                  autoSize={{ minRows:1, maxRows: 3 }} 
                                                  prefix={<Icons.MessageOutlined />} 
                                                  
                                                  value={searchTerm}
                                                  onChange={(e) => setSearchTerm(e.target.value)}
                                                  placeholder="Search..."
                                                  onPressEnter={(e)=> setSearchTerm(e.target.value)}
                                              />
                                    </Space.Compact>
                                    <Layout style={{backgroundColor:'White',height:'75vh',overflowY:'scroll',overflowX:'hidden'}}
                                        className='chat-panel-Panellist'
                                        ref={scrollContainerRef} 
                                    >
                                        <List
                                          dataSource={
                                            selectedTab === 'allMessages' ? 
                                                sessions.filter(session =>
                                                  (
                                                    session.variables.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    LastMessage[session.uid]?.toLowerCase().includes(searchTerm.toLowerCase())
                                                  ) 
                                                  
                                                ) 
                                            : 
                                            (selectedTab === 'NonAttended')
                                            ?
                                                sessions.filter(session =>
                                                  (session.variables.agent === null || session.variables.agent === undefined) &&
                                                  (
                                                    session.variables.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    LastMessage[session.uid]?.toLowerCase().includes(searchTerm.toLowerCase())
                                                  ) && 
                                                  (
                                                    (session.variables.status!=="closed")
                                                  )
                                                )

                                            :
                                                sessions.filter(session =>
                                                  (session.last_customer_message !== null && session.variables.agent === userData.username) &&
                                                  (
                                                    session.variables.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    LastMessage[session.uid]?.toLowerCase().includes(searchTerm.toLowerCase())
                                                  ) && 
                                                  (
                                                    (session.variables.status!=="closed")
                                                  )
                                                )
                                          }
                                          renderItem={session => (
                                            <Badge className="chat-panel-list-badge" style={{top:'12px',right: '22px'}} status="success" count={pendingMessageCounts[session.uid] } color={pendingMessageCounts[session.uid] >= 0 && pendingMessageCounts[session.uid] < 5 ? '#52c41a' : pendingMessageCounts[session.uid] >= 5 && pendingMessageCounts[session.uid] < 10 ? '#faad14' : '#f5222d' } >
                                              <List.Item className="chat-panel-list" style={{ width:'100%',backgroundColor: session === selectedSession ? '#ced8ff' : 'transparent' , cursor: 'pointer'}} onClick={() => handleSessionClick(session)}>
                                                  
                                                      <List.Item.Meta 
                                                            avatar={
                                                              (session.type === "Webhook") ? (
                                                                  <Icons.BugTwoTone style={{ paddingTop: '10px', fontSize: '30px', color: '#08c' }} />
                                                              ) : (session.type === "WebChat") ? (
                                                                  <Tooltip
                                                                      placement='right'
                                                                      title={`${(session.uid).substr(0, 30).replace("WebHook_", "").split("_")[0]} managed by ${(session.variables?.agent || '...')}`}
                                                                  >
                                                                      <Icons.GlobalOutlined 
                                                                          style={{
                                                                              paddingLeft: '5px',
                                                                              paddingTop: '12px',
                                                                              fontSize: '30px',
                                                                              color: orangeIconSessions.includes(session.uid) ? '#faad14' : (session.variables?.agent ? '#08c' : '#a2a2a2')
                                                                          }}
                                                                          onDoubleClick={() => handleIconDoubleClick(session)}
                                                                      />
                                                                  </Tooltip>
                                                              ) : (
                                                                <Tooltip
                                                                    placement='right'
                                                                    title={`${(session.uid).substr(0, 30).replace("WebHook_", "").split("_")[0]} managed by ${(session.variables?.agent || '...')}`}
                                                                >
                                                                    <Icons.WhatsAppOutlined
                                                                        style={{
                                                                            paddingLeft: '10px',
                                                                            paddingTop: '15px',
                                                                            fontSize: '30px',
                                                                            color: orangeIconSessions.includes(session.uid) ? '#faad14' : (session.variables?.agent ? '#08c' : '#a2a2a2')
                                                                        }}
                                                                        onDoubleClick={() => handleIconDoubleClick(session)}
                                                                    />
                                                                </Tooltip>
                                                              )
                                                          }
                                                          title={(session.customer)?session.customer.name: (session.variables?.name||  (session.uid).substr(0,30).replace("WebHook_","") || '') }
                                                          description={
                                                          <>
                                                          <div  className='PanelMessageStatus'>

                                                          </div>
                                                          <div className="PanelMessageLastMessage">{(LastMessage[session.uid])?.substr(0,50)|| ((session.history?.length>0)?(session.history[session.history.length-1].payload?.payload?.text|| session.history[session.history.length-1].payload?.msg):'...')}</div>
                                                          
                                                          <div className="PanelMessageSecondary">
                                                                  <div className="PanelMessageAuthor">
                                                                    {(session.variables?.agent ? (<><Icons.UserOutlined  style={{fontSize:'13px',paddingRight:'1px', color: (session.variables.status==="open")?'#ff0000':(session.variables.status==="processing")?'#b34914':'#00aa00' } } /> {session.variables.agent.split('@')[0]}</>) : '...')}
                                                                  </div>
                                                                  <div className="PanelMessageDate">
                                                                            {formatSpanishDate(LastMessageDate[session.uid]) || formatSpanishDate(session.updatedAt)}
                                                                                <Tooltip title={`Status ${session.variables.status||''}`}>
                                                                                  <Icons.CommentOutlined  style={{fontSize:'13px',paddingRight:'5px', color: (session.variables.status==="open")?'#ff0000':(session.variables.status==="processing")?'#b34914':'#00aa00' } } />
                                                                                </Tooltip> 
                                                                  </div>

                                                        
                                                            </div>
                                                          </> }
                                                          
                                                      />
                                              </List.Item>
                                              </Badge>
                                              
                                          )}
                                        />         
                                    </Layout>
                            </Col>
                            <Col span={16}>
                                <Layout style={{ height:'50vh', width: '100%',padding:'10px'}}>
                                      {selectedSession && (
                                        <>
                                          {(selectedSession.type === 'GupShup' || selectedSession.type === 'WhatsAppDirect' || selectedSession.type === 'WhatsAppLite') ? (
                                            <ChatWindow session={selectedSession} customer={selectedCustomer} userData={userData} onSave={handle_Save} onChange ={handle_Change} onReceived={{ messages: Recibidos[selectedSession.uid] }} onEvent={handle_Event}/>
                                          ) : selectedSession.type === 'Webhook' ? (
                                            <TimelineWindow session={selectedSession}  onReceived={{ nodes: Recibidos[selectedSession.uid]}} />
                                          ) : null}
                                        </>
                                      )}
                                </Layout>                               
                            </Col>
                      </Row>
                      <audio id="notificationSound">
                            <source src="public/notification.mp3" type="audio/mpeg" controls={true}/>
                      </audio>
                </Layout>
          
          )
}
export default ChatsPage

