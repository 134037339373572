import {  Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../Services/AuthService';
import { useContext } from 'react';

export const ProtectedRoute = () => {
  const  {isLogged} = useContext(AuthContext);
  return isLogged ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

