// import React, { useState } from 'react';
// import { Input, Row, Col, Form, Button, Switch, Tooltip } from 'antd';
// import * as Icons from '@ant-design/icons';
// 

// 
// const OptionKeyValue = ({ type, value, onChange,campo }) => {
//   const [options, setOptions] = useState(value || []);
//   const [jsonview, setJsonView] = useState(typeof value === 'object' || (typeof value === 'string' && value.includes('$')));

// 
//   const updateOptionsAndNotifyChange = (updatedValue) => {
//     setOptions(updatedValue);
//     onChange(campo, updatedValue);
//   };
// 
//   const handleInputChange = (key, newValue, field) => {
//     console.log(key,newValue,field)
//     if (jsonview && (typeof value === 'object' )) {
//       console.log("pretty campo",campo)
//       let updatedValue = [...options];
//      
//       updatedValue[key][field] = newValue; 
//       console.log("updatedValue",updatedValue)
//   //    
//       updateOptionsAndNotifyChange(updatedValue);
// 
//     } else {
//       console.log("sucio",newValue)
//   //    onChange(campo,newValue);
//       let updatedValue = [];
//       try {
//         updatedValue = JSON.parse(newValue);
//       } catch (e) {
// 
//       }
//       console.log("updatedValue",updatedValue)
//       updateOptionsAndNotifyChange(updatedValue);
//     }
//   };
// 
//   const handle_resetValue = () =>{
//     let aux = [{"key":"variable","value":null}]
//     setJsonView(true)
//     onChange(aux);
//     setOptions(aux);
//   }
//   
//   const handleView = () => {
//     setJsonView(!jsonview);
//   };
// 

// 

// 
//   return (
//     <>
//       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <Tooltip title={'Reset values'}>
//            <Button onClick={()=>handle_resetValue()} size={'small'} style={{ alignSelf: 'flex-start' }}><Icons.ReloadOutlined /></Button>
//         </Tooltip>
//        
//         <Switch
//           style={{ justifyContent: 'end' ,marginRight:'20px',minWidth:'60px'}}
//           checkedChildren="Pretty"
//           unCheckedChildren="Text"
//           defaultChecked={jsonview}
//           onChange={handleView}
//         />
//       </div>
//       {(jsonview && typeof value === "object") ? (
//         Object.entries(options).map(([key, elemento], index) => (
//           <Row key={index} gutter={8}>
//             <Col span={5}>
//               <Form.Item labelCol={{ span: 24 }} name={`no_save_key_${elemento.key}`} initialValue={elemento.key} label={index === 0 ? (campo==="variables")?'Fact':'Parameter' : ''}>
//                 <Input value={key} placeholder="Please enter a value" onBlur={(e) => handleInputChange(key, e.target.value, 'key')} />
//               </Form.Item>
//             </Col>
//             <Col span={17}>
//               <Form.Item labelCol={{ span: 24 }} name={`no_save_title_${elemento.key}`} initialValue={elemento.value} label={index === 0 ? 'Value' : ''}>
//                 <Input cmaxLength={maxLength} value={elemento.value} placeholder="Please enter a value" onBlur={(e) => handleInputChange(key, e.target.value, 'value')} />
//               </Form.Item>
//             </Col>
//             <Col span={2}>
//               {options.length > 1 ? (
//                 <Form.Item labelCol={{ span: 24 }} style={{ lineHeight: '30px' ,paddingTop:'5px'}} name={`no_save_delete_${index}`} label={index === 0 ? ' ' : ''}>
//                   <Tooltip title="Remove element">
//                     <Button size="small" type="primary" shape="circle" onClick={() => handleDelete(elemento.key)}>
//                       <Icons.RestOutlined />
//                     </Button>
//                   </Tooltip>
//                 </Form.Item>
//               ) : ''}
//             </Col>
//             </Row>
//  
//         ))
//       ) : (
//         <>
//             <Form.Item labelCol={{ span: 24 }} name={`options_texto`} initialValue={(typeof options === 'object') ? JSON.stringify(options) : JSON.stringify(options).replace(/"/g, '')} label={'Manual value'}>
//                 <Input showCount={true} maxLength={255} placeholder="Please enter a value" onChange={(e) => handleInputChange('options', e.target.value, 'value')}/>
//             </Form.Item>
//           {(typeof options === 'object') && JSON.stringify(options)}
//         </>
//       )}
// 
//     </>
//   );
// };
// 
// export default OptionKeyValue;


import React, { useState, useEffect } from 'react';
import { Input, Button,  Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';
 const getMaxKey = (data) => {
   let maxKey = 0;
   data.forEach((obj) => {
     if (!isNaN(obj.key)) {
       const key = parseInt(obj.key, 10);
       maxKey = Math.max(maxKey, key) + 1;
     } else {
       const keyNumber = parseInt(maxKey, 10);
       maxKey = isNaN(keyNumber) ? 0 : keyNumber + 1;
     }
   });
   return maxKey;
 };
const OptionKeyValue = ({ editKeys, type, campo,value, onChange }) => {
  const [options, setOptions] = useState((typeof value==="object"?value:[{ key: 'variable', value: null }]));
  const [jsonView, setJsonView] = useState(true);
   const limits = {
     "switch": 20,
     "optionButton":3
   }
   const maxLengths = {
     "switch": 20,
     "optionButton":20,
     "outboundConfig":500,
     "setVariable":100
   }
 
   const limit = limits[type] || 20;
   const maxLength = maxLengths[type] || 20;

   const aux_edit_keys=(editKeys!==undefined)?!editKeys:false

  useEffect(() => {
    // Aquí puedes hacer algo con el valor actualizado, por ejemplo, enviarlo a través de una prop
  }, [options]);

  const handleInputChange = (index, field, newValue) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = newValue;
    setOptions(updatedOptions);
    onChange(campo,updatedOptions);
  };

  const handleResetValue = () => {
    setJsonView(true);
    const updatedOptions = [{ key: 'variable', value: 'New value' }];
    setOptions(updatedOptions);
    onChange(campo,updatedOptions);
  };

  const handleIncrease = () => {
    const maxKey = getMaxKey(options);
    const updatedOptions = [...options, { key: maxKey.toString(), value: 'New value' }];
    setOptions(updatedOptions);
    onChange(campo,updatedOptions);
  };

  const handleDelete = (index) => {
    const updatedOptions = [...options.slice(0, index), ...options.slice(index + 1)];
    setOptions(updatedOptions);
    onChange(campo,updatedOptions);
  };
  return (
    <div>


      {jsonView ? (
        options.map((element, index) => (
          <div key={index} style={{ display: 'flex', marginBottom: '8px' }}>
            {editKeys}
            <Input
              style={{ width: '100px', marginRight: '8px' }}
              value={element.key}
              disabled={aux_edit_keys}
              onChange={(e) => handleInputChange(index, 'key', e.target.value)}
            />
            <Input
              style={{ flex: 1, marginRight: '8px' }}
              value={element.value}
              maxLength={maxLength} 
              showCount={true} 
              onChange={(e) => handleInputChange(index, 'value', e.target.value)}
            />
            {(options.length > 1 && (!aux_edit_keys)) && (
              <Button type="primary" shape="circle" onClick={() => handleDelete(index)}>
                <Icons.RestOutlined />
              </Button>
            )}
          </div>
        ))
      ) : (
        <Input.TextArea
          value={JSON.stringify(options)}
          autoSize={{ minRows: 3 }}
          onChange={(e) => setOptions(JSON.parse(e.target.value))}
        />
      )}
      {(jsonView &&  (options.length >= 0 && options.length < limit)  && !aux_edit_keys )&& (
        <Button type="primary" icon={<Icons.PlusOutlined />} onClick={handleIncrease}></Button>
      )}

<div style={{ display: 'flex', justifyContent: 'end' }}>
        <Tooltip title="Reset values">
        {(!aux_edit_keys )&& (
          <Button onClick={handleResetValue} size="small">
            <Icons.ReloadOutlined />
          </Button>
         )}
        </Tooltip>
{/* 
        <Switch checkedChildren="Pretty" unCheckedChildren="Text" defaultChecked={jsonView} onChange={handleView} /> */}
      </div>
    </div>
  );
};

export default OptionKeyValue;