import React, {  useState , useContext } from 'react';
import { Form, Input, Button, Modal, Space, Tooltip, Slider, Select, Switch } from 'antd';
import { CheckOutlined , CloseOutlined} from '@ant-design/icons';
import * as notificationService from '../Services/notifications';
import OptionKeyValue from './OptionKeyValue';
import { API_Request } from './../Services/APIService';
import { AuthContext } from './../Services/AuthService';
import { useNavigate } from 'react-router-dom';

const {TextArea} = Input;

export const FormBuilder = (props) => {
          const [deleteModalVisible, setDeleteModalVisible] = useState(false);
          const [formValues, setFormValues] = useState({}); 
          const [data] =useState(props.formData)
          const [timeout, settimeout] = useState(props.formData.timeout);
          const [currentInterval, setCurrentInterval] = useState('hour');
          const { userData } = useContext(AuthContext);
          const navigate = useNavigate();

          // Actualiza los valores del estado cuando se cambia un campo
          const handleFieldChange = (fieldName, value) => {
            props.formData[fieldName]=value
            setFormValues((prevValues) => ({
               ...prevValues,
               [fieldName]: value,
            }));
              if (fieldName==="session_time") {
                let newInterval = 'hour';
                    if (value < 1440) {
                      newInterval = 'hour';
                    } else if (value < 10080) {
                      newInterval = 'day';
                    } else if (value < 40320) {
                      newInterval = 'week';
                    }
                    setCurrentInterval(newInterval);
                    settimeout(value);

              }
          };
          const onFinish = (values) => {
            const filteredValues ={};
            props.formParams.forEach((param) => {
              //el nuevo y sino el anterior
              filteredValues[param.name] = (param.name==="attributes")?props.formData[param.name] : values[param.name];
              if (param.type!== undefined && param.type ==="boolean") {
                filteredValues[param.name] = (values[param.name]!=="")?values[param.name]:false
              }
            });
            const filteredObject = Object.fromEntries(
              Object.entries(filteredValues).filter(([key, value]) => !key.startsWith("no_save_"))
            );
            if (props.formData === false) {
                API_Request('POST', props.entity, filteredObject)
                .then((response) => {
                  notificationService.showInfoNotification('Data Saved!');
                })
                .catch((error) => {
                  notificationService.showErrorNotification(error.message);
                  console.log(error);
                })
                .finally(()=>{
                   navigate(-1);
                })                
              } else {
                API_Request('PUT', props.entity + '/' +(values['id']|| values['customerId']), filteredObject)
                .then((response) => {
                  notificationService.showInfoNotification('Data Updated!');
                })
                .catch((error) => {
                  notificationService.showErrorNotification(error.message);
                  console.log(error);
                });
              }

          };
        
          let initialValues = {}; // Objeto para almacenar los valores iniciales

          props.formParams.forEach((param) => {
                    if (typeof data[param.name] ==="object" && param.name !=="outboundConfig" && param.name !=="attributes") {
                      initialValues[param.name] = (data[param.name])?JSON.stringify(data[param.name]):''; 
                    } else {
                      initialValues[param.name] = data[param.name] || ''; 
                    }

          });
          
          const handleDelete = () => {
                    setDeleteModalVisible(true);
          };
                
          const handleDeleteConfirm = (id) => {
                    // Aquí puedes realizar la lógica de borrado
                    API_Request('DELETE', props.entity+"/"+props.formData.id)
                    .then((response) => {
                      notificationService.showSuccessNotification('Data Deleted!');
                    })
                    .catch((error) => {
                      notificationService.showErrorNotification(error.message);
                      console.log(error);
                    })
                    .finally(()=>{
                      setDeleteModalVisible(false);
                     navigate(-1);
                    })

          };
          
          const handleDeleteCancel = () => {
                    setDeleteModalVisible(false);
          };
                    
          const minutesToFormattedTime = (min) => {
            const totalSeconds = min * 60;
            const days = Math.floor(totalSeconds / (60 * 60 * 24));
            const remainingSecondsAfterDays = totalSeconds % (60 * 60 * 24);
            const hours = Math.floor(remainingSecondsAfterDays / 3600);
            const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;
            const minutes = Math.floor(remainingSecondsAfterHours / 60);
            const seconds = remainingSecondsAfterHours % 60;
          
            const formattedTime = [];
            if (days > 0) {
              formattedTime.push(`${days}d`);
            }
            if (hours > 0) {
              formattedTime.push(`${hours}h`);
            }
            if (minutes > 0) {
              formattedTime.push(`${minutes}m`);
            }
            if (seconds > 0 || formattedTime.length === 0) {
              formattedTime.push(`${seconds}s`);
            }
          
            return formattedTime.join(' ');
          };


       //  const handleSliderChange = (value) => {
       //    let newInterval = 'hour';
       //    if (value < 1440) {
       //      newInterval = 'hour';
       //    } else if (value < 10080) {
       //      newInterval = 'day';
       //    } else if (value < 40320) {
       //      newInterval = 'week';
       //    }
       //    setCurrentInterval(newInterval);
       //    settimeout(value);
       //  };

          const sliderMax = currentInterval === 'hour' ? 1440 : currentInterval === 'day' ? 10080 : 40320 ;

          const getSteps = () => {
            if (currentInterval === 'hour') {
              return 30;
            } else if (currentInterval === 'day') {
              return 60;
            } else if (currentInterval === 'week') {
              return 5040;
            }
          };   

          const getMarks = () => {
            const min = 60; // Mínimo en minutos
            const max = currentInterval === 'hour' ? 1440 : currentInterval === 'day' ? 10080 : currentInterval === 'week' ? 40320 : 10080;
            const interval = (max - min) / 3; // Dividir en 4 intervalos
          
            const marks = {};
            for (let i = min; i <= max; i += interval) {
              let formattedValue = '';
              if (currentInterval === 'hour') {
                formattedValue = `${Math.floor(i / 60)}h`;
              } else if (currentInterval === 'day') {
                formattedValue = `${Math.floor(i / 60 / 24)}d`;
              } else if (currentInterval === 'week') {
                formattedValue = `${Math.floor(i / 60 / 24 / 7)}w`;
              }
              marks[i] = formattedValue;
            }
            marks[max] = `${Math.floor(max / 60)}h`;
          
            return marks;
          };  

          const generateFormFields = () => {
                    return props.formParams.map((param,index) => {
                              const objetos = {
                                        "id":               <Input style={{display:'none'}} disabled  bordered={false}></Input>,
                                        "tag":              <Input disabled  bordered={false}></Input>,
                                        "customerId":       <Input disabled  bordered={false}></Input>,
                                        "enabled":          <><Switch onChange={(value) => handleFieldChange(param.name, value)}  defaultChecked={props.formData[param.name]}></Switch></>,
                                        "bot_enabled":      <><Switch onChange={(value) => handleFieldChange(param.name, value)}  defaultChecked={props.formData[param.name]}></Switch></>,
                                        "idAccount":        <Select onChange={(value) => handleFieldChange(param.name, value)} required  
                                                              style={{ width: 200 }}
                                                              options={userData.Accounts.map(item => ({value: item.id,label: item.name})) }/>,
                                        "idbot":            <Select onChange={(value) => handleFieldChange(param.name, value)} required  
                                                              style={{ width: 200 }}
                                                              options={userData.Accounts[0].Bots?.map(item => ({value: item.id,label: item.name})) }/>,                                                            
                                        "role":             <Select onChange={(value) => handleFieldChange(param.name, value)} required  
                                                                style={{ width: 200 }}
                                                                options={[{ value: 'Agent', label: 'Agent' },{ value: 'Supervisor', label: 'Supervisor' },{ value: 'Administrator', label: 'Administrator' }]}/>,
                                        "flow_type":        <Select onChange={(value) => handleFieldChange(param.name, value)} required  
                                                                style={{ width: 200 }}
                                                                options={[{ value: 'GupShup', label: 'GupShup' },{ value: 'Webhook', label: 'WebHook' }]}/>,
                                        "description":      <TextArea onChange={(value) => handleFieldChange(param.name, value)} key={'form_element_'+param.name} campo={param.name} showCount autoSize={{ minRows: 4 }}/>,
                                        "attributes":       <OptionKeyValue key={'form_element_attributes_'+param.name} type={param.name} campo={param.name} value={props.formData[param.name]} onChange={(field,value) => handleFieldChange(param.name, value)}/>,
                                        "outboundConfig":   <OptionKeyValue key={'form_element_OutboundConfig_'+param.name} type={data.type} campo={param.name} value={props.formData[param.name]} onChange={(field,value) => handleFieldChange(param.name, value)}/>,
                                        "session_time":     <Slider
                                                                step={getSteps()}
                                                                marks={getMarks()}
                                                                tooltip={{ formatter: (value) => minutesToFormattedTime(value, currentInterval), open: true }}
                                                                onChange={(value) => handleFieldChange(param.name, value)}
                                                                value={timeout}
                                                                min={60}
                                                                max={sliderMax}
                                                              />
                              }      
                              return (
                                        <div id={'form_element_'+param.name} key={'form_element_'+param.name} style={{ display: param.name === 'id' ? 'none' : 'block' }}>
                                                  <Form.Item key={param.name} rules={[{ required: param.required||false, message: 'Please input '+param.label }]} style={{fontWeight:'bold'}}  name={param.name} label={(param.label)?(param.label.charAt(0).toUpperCase() + param.label.slice(1)).replace("_"," "):param.name.charAt(0).toUpperCase()+ param.name.slice(1).replace("_"," ")}>
                                                            {objetos[param.name] || <Input key={'input_'+param.name} campo={param.name} showCount placeholder="Please enter a value"/>}
                                                  </Form.Item>
                                        </div>
                                      )
                    })
          }

          return (
                    <Form 
                              name="clientForm" 
                              labelCol={{ span: 4 }}
                              wrapperCol={{ span: 20 }}
                              onFinish={onFinish} 
                              initialValues={initialValues}
                    >
                              {generateFormFields()}
                              <div style={{ width:'250px',height:'55px',borderRadius:'50px',zIndex:'10000',padding:'15px',textAlign: 'right' ,right:'0px',backdropFilter: 'blur(10px)'}}>
                                      <Space wrap align='end'>
                                                <Form.Item>
                                                          <Tooltip title="Save this data" >
                                                                    <Button type="primary" htmlType="submit">
                                                                    {(props.formData === false) && 
                                                                            <>
                                                                            <CheckOutlined /> Create     
                                                                            </>                       
                                                                      }
                                                                    {(props.formData !== false) && 
                                                                            <>
                                                                            <CheckOutlined /> Save     
                                                                            </>                       
                                                                      }
                                                                    </Button>
                                                          </Tooltip>
                                                </Form.Item>
                                      </Space>
                                      {(props.formData !== false) && 
                                      <Tooltip title="Delete this data">
                                                <Button type="primary" danger onClick={handleDelete} style={{'marginLeft':'20px'}}>
                                                          <CloseOutlined /> Delete
                                                </Button>                                        
                                      </Tooltip>                              
                                      }
                              </div>
                              <Modal
                                        title="Confirm Delete"
                                        open={deleteModalVisible}
                                        onOk={handleDeleteConfirm}
                                        onCancel={handleDeleteCancel}
                              >
                                        <p>Are you sure you want to delete ?</p>
                              </Modal>

                    </Form>
          );
};