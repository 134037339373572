import React, { useEffect} from 'react';
import { Button, Space, Tooltip, Input, Form, Drawer,  Select} from 'antd';
import * as Icons from '@ant-design/icons';
import { showErrorNotification } from '../Services/notifications';
import OptionKeyValue from './OptionKeyValue';

export function FormSecondaryWindow ({ title, context, width,data, onSave,onClose,onDelete }) {
          const [form] = Form.useForm();

          const handle_Change = (field,newValue) => {
            form.setFieldValue(field,newValue)
          }

          const handle_FormSubmit = () => {
                    form
                      .validateFields()
                      .then((values) => {
                        const aux = {"entity":data.entity,context,"id":data.id,"fieldsForm":data.fieldsForm,"payload":values}

                        aux.fieldsForm.forEach(field => {
                            if(values[field.name] !== undefined) {
                                field.value = values[field.name];
                            }
                        });

                        onSave(aux);
                        
                        form.resetFields();
                      })
                      .catch((error) => {
                        showErrorNotification("Please Review data",error)
                        //console.error('Error al enviar el formulario:', error);
                      });
           };

          const handle_Delete = () => {
            form.resetFields();
            if(onDelete){
              onDelete(data.id);
            }
            
          };


          const handle_FormClose = () => {
                    onClose();
                    form.resetFields();
          };           

          const capitalizeFirstLowercaseRest = str => {
                  if (str === undefined || str.length ===1) return str
                  return (
                    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
                  );
          };


          const generate_FormFields = () => {
                  if (!data ) {
                    return null;
                  }

                    // Determinar los campos a incluir basados en el contexto
                    let fieldsToInclude = [];
                    if (context === 'add') {
                      fieldsToInclude = data.new;
                    } else if (context === 'modify') {
                      fieldsToInclude = data.modify;
                    } else {
                      // Si no es 'add' ni 'modify', incluir todos los campos por defecto
                      fieldsToInclude = data.fieldsForm.map(field => field.name);
                    }

                  return data.fieldsForm
                  .filter(item => fieldsToInclude.includes(item.name) && item.name !== "id") // Filtrar los campos a incluir
                  .map((elem,key) => {
                      const objetos = {
                        "attributes":       <OptionKeyValue key={'form_element_attributes_'+elem.name} type={elem.name} campo={elem.name} value={data.payload?.[elem.name] || ''} onChange={(field,value) => handle_Change(elem.name, value)}/>,
                        "role":             <Select 
                                              options={[{ value: 'Agent', label: 'Agent' },{ value: 'Supervisor', label: 'Supervisor' },{ value: 'Administrator', label: 'Administrator' }]}
                                            />,
                        "password":         <Input.Password key={key} campo={key} iconRender={(visible) => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />) }/>,
                      }
                      return (
                        <div key={'elemento'+key}>
                             <Form.Item key={key} style={{fontWeight:'bold'}} rules={[{ required: elem.required|| false, message: 'Please input '+elem.label }]}  name={elem.name} label={capitalizeFirstLowercaseRest(elem.label || elem.name)} tooltip={{ title:elem.info || '', icon: <Icons.InfoCircleOutlined /> }} >
                                   {objetos[elem.name] || <Input key={key} campo={key} required={elem.required || false}  placeholder="Please enter a value"/>}
                            </Form.Item>
                        </div>
                      )
                  })
          }
          useEffect(() => {
            if (data && data.payload) {
              form.setFieldsValue(data.payload);
            }
          }, [data, form]);

          return(
                    <Drawer
                          title={title}
                          placement="right"
                          onClose={handle_FormClose}
                          open={true}
                          width={width}
                          extra={
                                    <Space>
                                              <Tooltip title="Save data">
                                                <Button type="primary" style={{width:'100%'}} onClick={handle_FormSubmit}>
                                                  Confirm
                                                </Button>      
                                              </Tooltip>
                                    </Space>
                          }
                    >
                             <Form 
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  layout="vertical"
                                  form={form}
                                  initialValues={{}}
                                  onFinish={handle_FormSubmit}>
                                  <div style={{height:'80vh',overflowY:'hidden'}}>
                                    {generate_FormFields()}
                                  </div>
                            </Form>

                            {(data.id) && 
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Tooltip title="Delete this data">
                                  <Button type="primary" danger style={{justifySelf:'end'}} onClick={handle_Delete}>
                                      <Icons.CloseOutlined /> Delete
                                  </Button>                                
                              </Tooltip>
                            </div>
                             }
                           
                  </Drawer>
          )
}
