import React, { useState ,useContext} from 'react';
import { Form, Input, Button ,Row,Col} from 'antd';
import * as notificationService from '../Services/notifications';
import logo from './../logo.png'; // Importar el archivo de imagen del logo
import { AuthContext } from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { GlobalConfig } from '../App';

const Login = () => {
  const {_login} = useContext(AuthContext);
  const [loadings, setLoadings] = useState(false);

  const [username, setUsername] = useState('pruebas');
  const [password, setPassword] = useState('1234');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoadings(true)
    // Realizar lógica de autenticación aquí, por ejemplo, hacer una solicitud a la API
    // Si la autenticación es exitosa, llamar a la función onLogin
    // Pasar la información de inicio de sesión (por ejemplo, token de acceso) como argumento si es necesario
    // Ejemplo de llamada a la función onLogin sin argumentos:
    let x = await _login(username,password)
    if (x) {
      notificationService.showSuccessNotification('Inicio de sesión exitoso');
      setLoadings(false)
      navigate("/home")
      return true;
    } else {
      notificationService.showErrorNotification('No es posible el inicio de sesión');
      setLoadings(false)
      return false;
    }
  };

  return (
    <>
    <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
      <Col span={8} >
       <Form                               
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleSubmit}
      >
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 ,width:'100%'}}>
          <img src={logo} alt="Logo" style={{ width: 200 }} /> {/* Mostrar el logo */}
        </div>     
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Item label="Usuario" name="username" rules={[{ required: true, message: 'Por favor ingresa tu usuario' }]}>
          <Input width ={200} value={username} onChange={handleUsernameChange} />
        </Form.Item>
        <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }]}>
          <Input.Password width ={200} value={password} onChange={handlePasswordChange} autoComplete="on"/>
        </Form.Item>
        </div>   
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="primary" htmlType="submit" loading={loadings}>
            Iniciar sesión
          </Button>
          </div>
        </Form.Item>
      </Form>  
      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{justifyContent:'center',display:'flex',alignItems:'center', textAlign:'center'}}>
            v.<strong>{GlobalConfig.VERSION}</strong>&nbsp;Ⓡ {new Date().getFullYear()} 
      </Col>       
      </Col>
  
    </Row>
    <Row>

    </Row>
    </>
  );
};

export default Login;