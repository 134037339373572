import React, {  useState,useEffect} from 'react';
import { Button, Space ,Tooltip,Input , Form, Drawer,Image, Select, Slider,Row,Col} from 'antd';
import { InfoCircleOutlined , SettingOutlined} from '@ant-design/icons';
import JsonFormatter from './JsonFormatter'; 
import OptionKeyValue from './OptionKeyValue';


export function NodeEditor ({ data, onSave,onClose,onUpdate }) {
          const {Search } = Input;
          const {TextArea } = Input;
          const [form] = Form.useForm();
          const [childrenDrawer, setChildrenDrawer] = useState(false);
          const [delay, setdelay] = useState(data.delay);
          const [timeout, settimeout] = useState(data.timeout);

          const showChildrenDrawer = () => {
                    setChildrenDrawer(true);
          };
                
          const onChildrenDrawerClose = () => {
                    setChildrenDrawer(false);
          };

          const removeKeysWithNoSave = (obj) => {
            const filteredEntries = Object.entries(obj).filter(([key]) => !key.includes('no_save_'));
            const filteredObj = Object.fromEntries(filteredEntries);
            return filteredObj;
          };

          const handleFormSubmit = () => {
                    form
                      .validateFields()
                      .then((values) => {
                        values= removeKeysWithNoSave(values)
                        setdelay(values.delay)
                        const updatedData = { ...data };

                        if (values.wait) {
                          updatedData.wait = values.wait;
                          delete values.wait;
                        }
                  
                        if (values.delay) {
                          updatedData.delay = values.delay;
                          delete values.delay;
                        }

                        if (values) {
                          updatedData.payload = values;
                        }

                        if (values.outputs) {
                          updatedData.payload.new_outputs = values.outputs;
                          updatedData.payload.outputs = values.outputs;
                        } else {
                          delete updatedData.payload.outputs;
                        }
                        if (updatedData.payload.options_texto) {
                          updatedData.payload.options = updatedData.payload.options_texto
                          delete updatedData.payload.options_texto;
                        }

                        if (updatedData.payload.options_texto==="") {
                          updatedData.payload.options= []
                          delete updatedData.payload.options_texto;
                        }

                        onSave(updatedData);
                        form.resetFields();
                      })
                      .catch((error) => {
                        console.error('Error al enviar el formulario:', error);
                      });
           };

          const handleFormClose = () => {
                    onClose();
                    form.resetFields();
           };

          const capitalizeFirstLowercaseRest = str => {
                  return (
                    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
                  );
          };

          const onSearch = (value) => {
                  if (value) {
                    window.open(value, '_blank');
                  }
          };

          const handleTextAreaChange = (name, value) => {
            form.setFieldsValue({ [name]: value });
          };        
          const handleJsonChange = (field,newValue) => {
            form
            .setFieldsValue({ [field]: newValue });

            form
            .validateFields()
            .then((values) => {
              let updatedData = { ...data };
              updatedData= removeKeysWithNoSave(updatedData)
              updatedData.payload[field] = newValue
              onUpdate(updatedData)
            });
          }; 
        

          const generateFormFields = () => {
                    if (!data.payload) {
                      return null;
                    }
                
                    return Object.entries(data.payload).map(([key, valor]) => {
                      let inputValue = valor;
                      let inputValue_aux = valor
                      if (typeof valor === 'object') {
                        valor = JSON.stringify(inputValue);
                      }
                      const titulos = {
                        "msg" :"Mensaje para el usuario que puede tener variables de la sesion que empiezan por $ y atributos que empiezan por %",
                        "name": "Nombre del nodo " +data.type,
                        "method": "Metodos http para usar en el envio a n endpoint ",
                        "title": "Titulo del elemento " + data.type,
                        "url": "Dirección absoluta de internet que debe empezar por http://",
                        "fact": "Nombre de la variable a guardar en sesion o en atributos",
                        "options": "Lista de opciones para enviar al usuario, debe ser de este tipo [{\"key\":\"variable1\",\"value\":\"titulo\"}]",
                        "conditions": "Nombre de la variable a guardar en sesion o en atributos",
                        "headers": "Lista de cabeceras para enviar al endpoint, debe ser de este tipo [{\"key\":\"variable1\",\"title\":\"titulo\"}]",
                        "body": "Payload para enviar al endpoint, debe ser de este tipo [{\"key\":\"variable1\",\"title\":\"titulo\"}]",
                      }
                      const objetos = {
                        "headers":        <OptionKeyValue key={key} type={data.type} campo={key} value={inputValue} onChange={handleJsonChange} />,
                        "body":           <JsonFormatter key={key} campo={key} value={inputValue} onChange={handleJsonChange}/>,
                        "variables":      <OptionKeyValue key={key} type={data.type} campo={key} value={inputValue} onChange={handleJsonChange}/>,
                        "options":        <OptionKeyValue key={key} type={data.type} campo={key} value={inputValue} onChange={handleJsonChange}/>,
                        "conditions":     <JsonFormatter key={key} campo={key} value={inputValue} onChange={handleJsonChange} />,
                        "msg":            <TextArea  key={key} campo={key} value={inputValue} showCount autoSize={{ minRows: 4 }}/>,
                        "method":         <Select
                                              key={key} campo={key}
                                              value={inputValue}
                                              style={{ width: 120 }} 
                                              options={[
                                                { value: 'GET', label: 'GET' },
                                                { value: 'POST', label: 'POST' },
                                                { value: 'DELETE', label: 'DELETE' },
                                                { value: 'PUT', label: 'PUT' },
                                              ]}
                                           />,
                        "url":            <Search  key={key} campo={key}  onChange={handleTextAreaChange} placeholder="input search text" onSearch={onSearch} />
                      }
                      return (
                        <div key={'elemento'+key}>
                              <Form.Item key={key} style={{fontWeight:'bold'}} initialValue={JSON.stringify(inputValue)} name={key} label={capitalizeFirstLowercaseRest(key)} tooltip={{ title: titulos[key], icon: <InfoCircleOutlined /> }} >
                                    {objetos[key] || <Input key={key} campo={key}  value={JSON.stringify(inputValue)}   placeholder="Please enter a value"/>}
                              </Form.Item>
                              {(key==='url' && (data.type === "image" || data.type === "sticker" )) && <Image id={`id_${key}`} name={`image_${key}`} key= {`key_image_${key}`} src={inputValue_aux}></Image>}
                              {(key==='url' && data.type === "audio") && <audio controls><source src={inputValue} type="audio/mp3"/></audio>}
                              {(key==='url' && data.type === "video") && <video width={'100%'} controls><source src={inputValue} type="audio/mp4"/></video>}
                        </div>
                      )
                    });
          };

          useEffect(() => {
                    if (data && data.payload) {
                      form.setFieldsValue(data.payload);
                    }
                  }, [data, form]);     
          return(
                    <Drawer
                              title={'Edit details Node ' + data.type + ' ' + data.id}
                              placement="right"
                              onClose={handleFormClose}
                              open={true}
                              width={450}
                              extra={
                                        <Space>
                                                  <Tooltip title="Save data">
                                                    <Button type="primary" style={{width:'100%'}} onClick={handleFormSubmit}>
                                                      Save
                                                    </Button>      
                                                  </Tooltip>
                                                  <Button key={'boton2'} type={'icon'} onClick={showChildrenDrawer} icon={<SettingOutlined  />} />
                                        </Space>
                              }
                    >

                                  <Drawer
                                            title="Node Details"
                                            width={320}
                                            closable={false}
                                            onClose={onChildrenDrawerClose}
                                            open={childrenDrawer}
                                  >
                                            Datos del nodo
                                  </Drawer>
                      <Form 
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            layout="vertical"
                            form={form}
                            initialValues={{ wait:data.wait, delay:data.delay}}
                            onFinish={handleFormSubmit}>
                            <Row>
                              <Col span={12}>
                                    <Form.Item name={'delay'} style={{fontWeight:'bold'}}  key={'delay'} label={'Delay '+ (delay/1000) + 's'} tooltip={{ title: 'Delay bot response (ms)', icon: <InfoCircleOutlined /> }} >
                                          <Slider onChange = {setdelay} value={data.delay} min={100} max={60000} step={100}></Slider>
                                    </Form.Item>  
                              </Col>
                              <Col span={12}>
                                    <Form.Item name={'timeout'} style={{fontWeight:'bold'}}  key={'timeout'} label={'Timeout '+ (timeout/1000) + 's'} tooltip={{ title: 'Timeout bot response (ms)', icon: <InfoCircleOutlined /> }} >
                                          <Slider onChange = {settimeout} value={data.timeout} min={100} max={60000} step={100}></Slider>
                                    </Form.Item>  
                              </Col>                              
                            </Row>   
                            <div className = {'scroll-pretty'}style={{height:'75vh',overflowY:'scroll'}}>
                               {generateFormFields()}
                            </div>
                      </Form>
                  



                  </Drawer>
          )

}
