import axios from "axios"
import {GlobalConfig} from './../App'
import * as notificationService from '../Services/notifications';

export const API_Request = (method,url,body) => {
         // notificationService.showInfoNotification('Generando peticion');
         let url_aux = GlobalConfig.API_BACKEND+( (url.substring(0,1) ==="/")?url: "/"+url)
          return axios({
                    headers: {
                      "Content-Type": "application/json" ,
                      "apiKey": window.localStorage.getItem("apiKey")
                    },
                    maxBodyLength: Infinity,
                    method: method.toLowerCase(),
                    url: url_aux,
                    data: body
          })
          .then((result) => {
                    return result
          })
          .catch((error) => {
                    notificationService.showErrorNotification('An error non expected, ' +error.message);
                    console.log(error.message)
                    return error
          });
}

export const formatSpanishDate = (input, explicit = false) => {
          if (typeof input === 'undefined') {
            return '';
          }
        
          const date = typeof input === 'string' ? new Date(input) : new Date(input * 1000);
          const currentDate = new Date();
        
          if (explicit) {
            const day = date.getDate();
            const month = date.toLocaleString('es-ES', { month: 'long' });
            const year = date.getFullYear();
            const time = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        
            return `${day} de ${month} de ${year} ${time}`;
          }
        
          const timeDifference = currentDate.getTime() - date.getTime();
          const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        
          if (date.toDateString() === currentDate.toDateString()) {
            if (minutesDifference <=0 ) {
              return `Now`;
            }
            if (minutesDifference < 5) {
              return `${minutesDifference} minutes ago...`;
            } else {
              return `Today ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}h`;
            }
          } else {

            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            if (daysDifference > 1) {
              return `${daysDifference} days ago ${date.toLocaleTimeString('es-ES', {hour: '2-digit',minute: '2-digit'})}h`;
  
            } else {
              return `Yesterday ${date.toLocaleTimeString('es-ES', {hour: '2-digit',minute: '2-digit'})}h`;
  
            }

            //return date.toLocaleString('es-ES', {
            //  day: 'numeric',
            //  month: 'long',
            //  year: 'numeric',
            //  hour: '2-digit',
            //  minute: '2-digit',
            //  second: '2-digit'
            //});
          }
};