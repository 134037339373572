import React, { useState } from 'react';
import { Upload, Button, Progress } from 'antd';
import * as Icons from '@ant-design/icons';
import { showErrorNotification, showInfoNotification } from '../Services/notifications';

const FileUploader = ({title, onCSVUpload }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const processCSVRows = async (file) => {
    const reader = new FileReader();
    const rows = [];
    let totalRows = 0;

    reader.readAsText(file);

    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const text = event.target.result;
        const lines = text.split('\n');

        totalRows = lines.length;
        const columnNames = lines[0].split(';');

        if (!columnNames.includes('phone') ) {
          reject(new Error("El archivo CSV debe contener las columnas 'phone'"));
          return;
        }

        for (let i = 1; i < totalRows; i++) {
          const line = lines[i].trim();
          if (line) {
            const columns = line.split(';');
            rows.push(columns);
          }
        }

        const processedData = await processCSVData(rows, columnNames);
        resolve(processedData);
      };

      reader.onerror = (event) => {
        reject(new Error('Hubo un error al leer el archivo.'));
      };
    });
  };

  const processCSVData = async (rows, columnNames) => {
    const totalRows = rows.length;
    const processedData = [];

    for (let i = 0; i < totalRows; i++) {
      const row = rows[i];
      const progressPercentage = Math.round((i / totalRows) * 100);
      setProgress(progressPercentage);

      const attributes = [];
      for (let j = 1; j < row.length; j++) {
        const columnName = columnNames[j];
        const attribute = {
          key: columnName,
          value: row[j],
        };
        attributes.push(attribute);
      }

      const processedRow = {
        phone: row[0],
        attributes: (attributes),
      };

      processedData.push(processedRow);
    }
    onCSVUpload(processedData)
    return processedData;
  };

  const beforeUpload = async (file) => {
    setUploading(true);
    setProgress(0);

    try {
      const processedData = await processCSVRows(file);

      showInfoNotification(processedData.length + ' rows uploaded !!!');

    } catch (error) {
      showErrorNotification('Hubo un error al insertar los datos en la base de datos.');
      console.error(error);
    } finally {
      setUploading(false);
    }

    // Evitamos que Ant Design maneje la carga del archivo
    return false;
  };

  return (
    <div>
      <Upload
        customRequest={() => {}}
        beforeUpload={beforeUpload}
        showUploadList={false}
      >
        <Button icon={<Icons.UploadOutlined />} disabled={uploading}>
          {title}
        </Button>
      </Upload>
      {uploading && (
        <div style={{ marginTop: 10 }}>
          <Progress percent={progress} status="active" />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
