import React, { useState } from 'react';
import { Switch, Alert } from 'antd';
import  TextArea from 'antd/es/input/TextArea';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
const JsonFormatter = ({campo, value, onChange }) => {
    const [jsonValue, setJsonValue] = useState(value);
    const [jsonViewPretty, setjsonViewPretty] = useState(true);
    const [jsonError, setJsonError] = useState(null);

    const handleJsonChange = (e) => {
       const newValue = e.target.value;
       setJsonValue(newValue);
       setIsJsonString(true);
    };
    const handle_p = (data) => {
      console.log("DATA",data)
    }
    
    const handleEdit = ({src}) => {
      console.log("campo",campo,"src",src)
      setJsonValue(src);
      onChange(campo,src); 
    };
    const handleAdd = ({ updated_src }) => {
      setJsonValue(updated_src);
      onChange(campo,updated_src); 
    };
    const handleDelete = ({ updated_src }) => {
      setJsonValue(updated_src);
      onChange(campo,updated_src); 
    };
    const handleView = () => {
      setjsonViewPretty(!jsonViewPretty);
    };
    const handleTextAreaChange = (event) => {
      let newValue = event.target.value
      try {
        const parsedValue = JSON.parse(newValue);
        setJsonValue(parsedValue);
        setJsonError(null);
        onChange(campo, parsedValue);
      } catch (error) {
        setJsonValue(newValue);
        setJsonError('JSON inválido');
        onChange(campo, newValue);
      }
    };
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Switch
            style={{ marginLeft: '10px' }}
            checkedChildren="Json"
            unCheckedChildren="Text"
            defaultChecked
            onChange={handleView}
          />
        </div>
        {jsonViewPretty ? (

<JsonView 
src={jsonValue}  
editable 
enableClipboard 
onChange={handle_p}
/>

        //  <JsonView 
        //      src={jsonValue} 
        //      editable
        //      enableClipboard
        //      onAdd={handleAdd}
        //      onChange={handleEdit}
        //      onDelete={handleDelete}
        //      onEdit={handleEdit}
        //  
        //  />
        //  <ReactJson
        //    src={jsonValue}
        //    theme="monokai"
        //    displayDataTypes={false}
        //    displayObjectSize={true}
        //    name={null}
        //    style={{ backgroundColor: '#282c34', padding: '2px' }}
        //    onEdit={handleEdit}
        //    onAdd={handleAdd}
        //    onDelete={handleDelete}
        //    enableClipboard={false}
        //    shouldCollapse={({ src }) => Object.keys(src).length > 20}
        //    collapseStringsAfterLength={40}
        //    indentWidth={4}
        //    iconStyle="circle"
        //    displayAdd={true}
        //    quotesOnKeys={false}
        //    displayDelete={true}
        //    displayEdit={true}
        //    onToggleArray={(open, index) => {
        //      if (open) {
        //        setJsonValue((prevValue) => ({
        //          ...prevValue,
        //          [index]: {},
        //        }));
        //      } else {
        //        setJsonValue((prevValue) => {
        //          const newValue = { ...prevValue };
        //          delete newValue[index];
        //          return newValue;
        //        });
        //      }
        //    }}
        //  />
        ) : (
          <>
          {jsonError && <Alert type="error" message={jsonError} />}
          <TextArea
            value={(typeof jsonValue === "object" )? JSON.stringify(jsonValue): jsonValue}
            onChange={handleTextAreaChange}
            style={{ width: '100%', height: '200px' }}
          />
          </>
        )}
      </>
    );

};
export default JsonFormatter;