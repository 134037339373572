import { message } from 'antd';
export function showSuccessNotification  (messageText)  {
          message.success(messageText,1);
}

export function showErrorNotification  (messageText)  {
          message.error(messageText);
}

export function showInfoNotification  (messageText) {
          message.info(messageText);
}

export function showWarningNotification(messageText) {
          message.warning(messageText);
}
